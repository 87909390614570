import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px, rgba(0, 0, 0, 0.24) 0px 1px;
  text-transform: uppercase;
  padding: 8px 0px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
`;

const CaretContainer = styled.span`
  margin: 0px 8px 0px 0px;
  float: left;
`;

const Content = styled.div`
  display: none;
  overflow: hidden;

  &.open {
    display: block;
    padding: 0px 16px;
  }
`;

function FoldableTable({ title, children, startOpen = true }) {
  const [open, setOpen] = useState(startOpen);

  return (
    <Panel>
      <TitleContainer onClick={() => setOpen(!open)}>
        <CaretContainer>
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </CaretContainer>
        <div>{title}</div>
      </TitleContainer>
      <Content className={open ? "open" : ""}>{children}</Content>
    </Panel>
  );
}

export default FoldableTable;
