import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { localizedRoutingRegex } from "../utils";

import { selectLanguage, selectCurrentLanguage } from "../store/profileSlice";

import { DEFAULT_LANGUAGE } from "../constants";

const LocalizedRoute = ({ path, exact, children }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const currentLanguage = useSelector(selectCurrentLanguage);

  return (
    <Route exact={exact} path={path}>
      {({ match, location }) => {
        const { language = currentLanguage } = match.params;

        const { pathname, search, hash } = location;

        if (!i18n.options.supportedLngs.includes(language)) {
          return (
            <Redirect to={`/${DEFAULT_LANGUAGE}${pathname}${search}${hash}`} />
          );
        } else if (!pathname.match(localizedRoutingRegex(language))) {
          return <Redirect to={`/${language}${pathname}${search}${hash}`} />;
        }

        dispatch(selectLanguage(language));

        return children;
      }}
    </Route>
  );
};

export default LocalizedRoute;
