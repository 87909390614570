import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import auctionsEn from "./assets/locales/en/auctions.json";
import goodsHandoverEn from "./assets/locales/en/goodsHandover.json";
import impersonationEn from "./assets/locales/en/impersonation.json";
import sidebarEn from "./assets/locales/en/sidebar.json";
import debriefEn from "./assets/locales/en/debrief.json";

import auctionsFr from "./assets/locales/fr/auctions.json";
import goodsHandoverFr from "./assets/locales/fr/goodsHandover.json";
import impersonationFr from "./assets/locales/fr/impersonation.json";
import sidebarFr from "./assets/locales/fr/sidebar.json";
import debriefFr from "./assets/locales/fr/debrief.json";

import auctionsNl from "./assets/locales/nl/auctions.json";
import goodsHandoverNl from "./assets/locales/nl/goodsHandover.json";
import impersonationNl from "./assets/locales/nl/impersonation.json";
import sidebarNl from "./assets/locales/nl/sidebar.json";
import debriefNl from "./assets/locales/nl/debrief.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        auctions: auctionsEn,
        goodsHandover: goodsHandoverEn,
        impersonation: impersonationEn,
        sidebar: sidebarEn,
        debrief: debriefEn,
      },
      fr: {
        auctions: auctionsFr,
        goodsHandover: goodsHandoverFr,
        impersonation: impersonationFr,
        sidebar: sidebarFr,
        debrief: debriefFr,
      },
      nl: {
        auctions: auctionsNl,
        goodsHandover: goodsHandoverNl,
        impersonation: impersonationNl,
        sidebar: sidebarNl,
        debrief: debriefNl,
      },
    },
    fallbackLng: "en",
    supportedLngs: ["en", "fr", "nl"],
    preload: ["en", "fr", "nl"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    detection: {
      lookupLocalStorage: "language",
    },
  });

export default i18n;
