import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalHeader, ModalBody } from "vavato-ui";

import { selectCurrentLanguage } from "../store/profileSlice";

const MainContentWrapper = styled.div`
  display: flex;
  margin: auto;
`;

const MainImage = styled.img`
  width: 600px;
  height: 400px;
  object-fit: contain;
  margin-left: 30px;
  margin-right: 30px;
`;

const NavigationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NavigationIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ThumbnailStrip = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
  margin-top: 20px;
  max-width: 700px;
`;

const Thumbnail = styled.img`
  object-fit: contain;
  cursor: pointer;
`;

const LotLightbox = ({ lot, open, closeLightbox }) => {
  const currentLanguage = useSelector(selectCurrentLanguage);

  useEffect(
    () => open && setMainImage(lot.image_urls[0]),
    [open, lot.image_urls]
  );

  const [mainImage, setMainImage] = useState(lot.image_urls[0]);

  const nextImage = () => {
    const mainImageIndex = lot.image_urls.indexOf(mainImage);

    if (mainImageIndex + 1 >= lot.image_urls.length) {
      setMainImage(lot.image_urls[0]);
    } else {
      setMainImage(lot.image_urls[mainImageIndex + 1]);
    }
  };

  const previousImage = () => {
    const mainImageIndex = lot.image_urls.indexOf(mainImage);

    if (mainImageIndex - 1 < 0) {
      setMainImage(lot.image_urls[lot.image_urls.length - 1]);
    } else {
      setMainImage(lot.image_urls[mainImageIndex - 1]);
    }
  };

  const bodyContent =
    lot.image_urls.length > 1 ? (
      <>
        <MainContentWrapper>
          <NavigationIconWrapper>
            <NavigationIcon
              onClick={previousImage}
              icon={faChevronLeft}
              size={"4x"}
            ></NavigationIcon>
          </NavigationIconWrapper>
          <a
            href={lot.url}
            title={lot.name_translations[currentLanguage]}
            rel="noopener noreferrer"
            target="_blank"
          >
            <MainImage src={mainImage.large}></MainImage>
          </a>
          <NavigationIconWrapper>
            <NavigationIcon
              onClick={nextImage}
              icon={faChevronRight}
              size={"4x"}
            ></NavigationIcon>
          </NavigationIconWrapper>
        </MainContentWrapper>

        <ThumbnailStrip>
          {lot.image_urls.map((image_url) => (
            <Thumbnail
              key={image_url.small}
              onClick={() => setMainImage(image_url)}
              src={image_url.small}
            ></Thumbnail>
          ))}
        </ThumbnailStrip>
      </>
    ) : (
      <MainImage src={mainImage.large}></MainImage>
    );

  return (
    <Modal modalOpen={open} closeModal={closeLightbox}>
      <ModalHeader>
        <h1>{lot.name_translations[currentLanguage]}</h1>
      </ModalHeader>
      <ModalBody>{bodyContent}</ModalBody>
    </Modal>
  );
};

export default LotLightbox;
