import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Table, formatCurrency } from "vavato-ui";

import { totalValue } from "../utils/ui";

import { loadingAuction, selectLots } from "../store/auctionsSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

const SubHeader = styled.tr`
  border-bottom: 1px solid black !important;
`;

const CloseAuctionStatsTable = () => {
  const { t } = useTranslation("auctions");

  const currentLanguage = useSelector(selectCurrentLanguage);

  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  if (loading) return null;

  const lotsAssigned = lots.filter((lot) => lot.assignment);
  const lotsPaid = lotsAssigned.filter(
    (lot) => lot.assignment.paid || lot.assignment.delivered
  );
  const lotsDelivered = lotsAssigned.filter((lot) => lot.assignment.delivered);

  const assignmentsPerCountry = lotsAssigned.reduce((countries, lot) => {
    const country =
      lot.assignment.buyer_entity.country_name_translations[currentLanguage] ||
      "Undefined";

    if (!countries.hasOwnProperty(country)) {
      countries[country] = 0;
    }

    countries[country]++;

    return countries;
  }, {});

  const sortedAssignmentsPerCountry = Object.entries(
    assignmentsPerCountry
  ).sort(([key1, value1], [key2, value2]) => value2 - value1);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("statistics")}</th>
        </tr>
      </thead>
      <tbody>
        <SubHeader>
          <td>{t("allLotsInvoiced")}</td>
          <td className="number">{lotsAssigned.length}</td>
          <td className="number">
            {formatCurrency(totalValue(lotsAssigned, "bid_price_in_euro"))}
          </td>
        </SubHeader>
        <tr>
          <td>{t("lotsPaid")}</td>
          <td className="number">{lotsPaid.length}</td>
          <td className="number">
            {formatCurrency(totalValue(lotsPaid, "bid_price_in_euro"))}
          </td>
        </tr>
        <tr>
          <td>{t("lotsDelivered")}</td>
          <td className="number">{lotsDelivered.length}</td>
          <td className="number">
            {formatCurrency(totalValue(lotsDelivered, "bid_price_in_euro"))}
          </td>
        </tr>
        <tr />
        <SubHeader>
          <td>{t("bidsFrom")}</td>
          <td />
          <td />
        </SubHeader>
        {sortedAssignmentsPerCountry.map(([country, total]) => (
          <tr key={country}>
            <td>{country}</td>
            <td className="number">{total}</td>
            <td />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CloseAuctionStatsTable;
