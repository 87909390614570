import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SignaturePad from "react-signature-pad-wrapper";
import { Button } from "vavato-ui";

const SignatureInputContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

const SignatureHeading = styled.h5`
  color: ${(props) => props.theme.tableHeaders} !important;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const SignatureBox = styled.div`
  border: 1px dashed ${(props) => props.theme.secondary};
`;

const StyledButton = styled(Button)`
  align-self: flex-start;
  margin-left: 0;
`;

const Signature = styled.img`
  width: 100%;
  height: 100%;
`;

const SignatureInput = forwardRef(
  ({ onWrite = () => {}, onErase = () => {}, image }, ref) => {
    const { t } = useTranslation("goodsHandover");

    return (
      <SignatureInputContainer>
        <SignatureHeading>{t("signature.title")}</SignatureHeading>
        <SignatureBox>
          {image ? (
            <Signature src={image} alt={t("signature.alternativeText")} />
          ) : (
            <SignaturePad
              ref={ref}
              options={{ onBegin: onWrite }}
            ></SignaturePad>
          )}
        </SignatureBox>
        {ref ? (
          <StyledButton
            className="outline"
            onClick={() => {
              onErase();
              ref.current.clear();
            }}
          >
            {t("tryAgain")}
          </StyledButton>
        ) : (
          ""
        )}
      </SignatureInputContainer>
    );
  }
);

export default SignatureInput;
