import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import { fetchData, putData, postData } from "../api-service";
import { queryParams } from "vavato-ui";
import { extraReqHeaders, handleErrors } from "../utils/api";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

import { upload } from "../utils/ActiveStorageUploader";
import { getBlob } from "../utils/ActiveJS";
import {
  offlineQueues,
  syncOffline,
  retrieveIdProofFromOfflineQueue,
  storeFailedDelivery,
  storeFailedSignatureUpload,
  storeFailedIdProofUpload,
} from "../utils/OfflineService";

import { success, error, info } from "./toasterSlice";
import { lotsDelivered } from "./auctionsSlice";

const goodsHandoverAdapter = createEntityAdapter({
  selectId: (goodsHandover) => goodsHandover.buyer_entity.id,
  sortComparer: (a, b) =>
    a.buyer_entity.user_name.localeCompare(b.buyer_entity.user_name),
});

const initialState = goodsHandoverAdapter.getInitialState({
  dataLoaded: false,
  loading: true,
  syncingOfflineDeliveries: false,
  selectedBuyerId: null,
  selectedAssignmentIds: [],
  commentAssignment: null,
  buyerQuery: "",
  lotQuery: "",
  forPickup: null,
  currentColumn: "buyers",
  idProofUploading: false,
  delivering: false,
  currentDeliveredBy: "",
  transporterMode: false,
  transporterCompany: "",
  transporterName: "",
  transporterLicensePlate: "",
});

export const fetchAllGoodsHandover = createAsyncThunk(
  "goodsHandover/fetchAllGoodsHandover",
  async (
    {
      selectedTimeInterval,
      searchedBuyerId,
      selectedAuctionId,
      selectedSearchQuery,
    },
    { getState, dispatch }
  ) => {
    const { canImpersonate } = getState().profile;
    const { impersonatedSeller } = getState().impersonate;

    const query = queryParams({
      release_date: selectedTimeInterval,
      auction_id:
        canImpersonate && !impersonatedSeller ? selectedAuctionId : null,
      buyer_id: searchedBuyerId,
      query_string: selectedSearchQuery,
    });

    const path = getState().goodsHandover.forPickup
      ? "/seller/goods_handovers"
      : "/seller/goods_handover_deliveries";

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}${path}${query}`,
      extraReqHeaders(impersonatedSeller)
    );

    handleErrors(result, "Error fetching auctions", dispatch, error);
    return result.json();
  }
);

export const saveCommentAssignment = createAsyncThunk(
  "goodsHandover/saveCommentAssignment",
  async ({ id, issueType, comments }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const params = {
      issue_type: issueType,
      comments: comments,
    };
    const domain = getState().environment.REACT_APP_API_URL;
    const result = await putData(
      `${domain}/seller/assignments/${id}`,
      params,
      extraHeaders
    );

    if (result.ok) {
      logEvent(analytics, "comment", {
        assignment_id: id,
        issue_type: issueType,
        comments: comments,
        auction_id: getState().goodsHandover.selectedAuctionId,
      });
      dispatch(success("Comment saved successfully"));
      return { id, issueType, comments };
    } else {
      handleErrors(result, "Error saving comment", dispatch, error);
    }
  }
);

export const saveIdProof = createAsyncThunk(
  "goodsHandover/saveIdProof",
  async ({ assignmentIdsByBuyerId, file }, { getState, dispatch }) => {
    const assignmentIds = Object.values(assignmentIdsByBuyerId).flat();
    const buyerIds = Object.keys(assignmentIdsByBuyerId).map(Number);

    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const params = { assignment_ids: assignmentIds };

    try {
      const signedId = await upload(file, extraHeaders);

      params.id_proof = signedId;

      const domain = getState().environment.REACT_APP_API_URL;
      await postData(
        `${domain}/seller/goods_handover_id_proofs`,
        params,
        extraHeaders
      );

      logEvent(analytics, "upload_id_proof", {
        assignment_ids: assignmentIds,
        auction_id: getState().goodsHandover.selectedAuctionId,
        id_proof: signedId,
      });
    } catch (_exception) {
      await Promise.all(
        buyerIds.map(async (buyerEntityId) => {
          await storeFailedIdProofUpload(buyerEntityId, file, params.id_proof);
        })
      );
    }

    return {
      buyerIds,
      url: URL.createObjectURL(file),
      signed_id: params.id_proof,
    };
  }
);

export const enqueueOfflineDelivery = createAsyncThunk(
  "goodsHandover/enqueueOfflineDelivery",
  async ({ assignmentIdsByBuyerId, signaturePadRef, params }, { dispatch }) => {
    await storeFailedDelivery(assignmentIdsByBuyerId, params);
    dispatch(info("Goods added to queue for syncing when getting online"));
    signaturePadRef.current?.clear();

    return assignmentIdsByBuyerId;
  }
);

export const deliverAssignments = createAsyncThunk(
  "goodsHandover/deliverAssignments",
  async (
    {
      assignmentIdsByBuyerId,
      idProof,
      signaturePadRef,
      deliveredBy,
      advancedFormFields,
    },
    { getState, dispatch }
  ) => {
    const assignmentIds = Object.values(assignmentIdsByBuyerId).flat();
    const buyerIds = Object.keys(assignmentIdsByBuyerId).map(Number);

    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const params = { assignment_ids: assignmentIds };

    if (advancedFormFields) {
      params.delivered_by = deliveredBy;

      if (getState().goodsHandover.transporterMode) {
        params.transporter_company_name =
          getState().goodsHandover.transporterCompany;
        params.transporter_license_plate_number =
          getState().goodsHandover.transporterLicensePlate;
        params.transporter_name = getState().goodsHandover.transporterName;
      }
    }

    if (!signaturePadRef.current.isEmpty()) {
      const signatureBlob = await getBlob(
        signaturePadRef.current.canvas,
        `signature-${buyerIds[0]}`
      );

      try {
        const signatureSignedId = await upload(signatureBlob, extraHeaders);

        params.signature = signatureSignedId;
      } catch (_exception) {
        await Promise.all(
          buyerIds.map(async (buyerEntityId) => {
            await storeFailedSignatureUpload(buyerEntityId, signatureBlob);
          })
        );
      }
    }

    if (idProof?.signed_id) {
      params.id_proof = idProof.signed_id;
    } else {
      params.id_proof = await retrieveIdProofFromOfflineQueue(
        buyerIds,
        getState().goodsHandover,
        goodsHandoverAdapter,
        extraHeaders
      );
    }

    let result;
    try {
      const domain = getState().environment.REACT_APP_API_URL;
      result = await postData(
        `${domain}/seller/goods_handover_deliveries`,
        params,
        extraHeaders
      );
    } catch (_exception) {
      dispatch(
        enqueueOfflineDelivery({
          assignmentIdsByBuyerId,
          signaturePadRef,
          params,
        })
      );
      throw new Error("Server could not be reached");
    }

    handleErrors(result, "Could not complete goods handover", dispatch, error);

    logEvent(analytics, "purchase", {
      currency: "EUR",
      transaction_id: assignmentIds[0],
      value: assignmentIds.length,
      items: assignmentIds.map((assignment) => {
        return {
          item_id: assignment,
          item_name: assignment,
          currency: "EUR",
          index: assignmentIds.indexOf(assignment) + 1,
          quantity: 1,
        };
      }),
    });
    dispatch(lotsDelivered(assignmentIds.length));
    dispatch(success("Goods delivered successfully"));

    signaturePadRef.current?.clear();

    return assignmentIdsByBuyerId;
  }
);

export const syncOfflineDeliveries = createAsyncThunk(
  "goodsHandover/syncOfflineDeliveries",
  async (_, { getState, dispatch }) => {
    const domain = getState().environment.REACT_APP_API_URL;
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    let errorOccured = false;

    const result = {};
    const deliveries = await offlineQueues.deliveries.toArray();

    await Promise.all(
      deliveries.map(async (row) => {
        try {
          const processed = await syncOffline(row, domain, extraHeaders);

          if (processed) {
            result[row.buyerEntityId] = result[row.buyerEntityId] || [];
            result[row.buyerEntityId].push(...row.assignmentIds);
            offlineQueues.deliveries.delete(row.id);
          } else {
            errorOccured = true;
          }
        } catch (_exception) {
          // This is most likely an error in syncOffline, as the user might
          // not actually be online, and still trying to sync.
          errorOccured = true;
        }
      })
    );

    logEvent(analytics, "offline_sync", {
      assignment_ids: deliveries.flatMap((row) => row.assignmentIds),
      count: deliveries.length,
    });

    if (errorOccured) {
      dispatch(error("Could not complete all goods handover"));
    } else {
      dispatch(success("Goods delivered successfully"));

      // TODO: This clearing is a trade-off, there could still be some
      // ID proof picture in the buyerProofs queue, taken for some buyer
      // and then not delivered. But this should be a rare case.
      offlineQueues.buyerProofs.clear();
    }

    dispatch(lotsDelivered(Object.values(result).flat().length));
    return result;
  }
);

// Shared reducer for multiple fulfilled actions
const removeDeliveredAssignmentsFromGoodsHandovers = (state, action) => {
  state.lotQuery = "";
  state.buyerQuery = "";

  // Transporter data has to be reset between each delivery
  state.transporterMode = false;
  state.transporterCompany = "";
  state.transporterLicensePlate = "";
  state.transporterName = "";

  Object.entries(action.payload).forEach(([buyerId, assignmentIds]) => {
    removeBuyerDeliveredAssignments(state, buyerId, assignmentIds);
  });
};

const removeBuyerDeliveredAssignments = (
  state,
  buyerId,
  assignmentIds,
  changed
) => {
  const goodsHandover = state.entities[buyerId];

  if (!goodsHandover) return;

  const remainingAssignments = goodsHandover.assignments.filter(
    (assignment) => !assignmentIds.includes(assignment.id)
  );

  if (remainingAssignments.length) {
    goodsHandoverAdapter.updateOne(state, {
      id: buyerId,
      changes: { assignments: remainingAssignments, changed: changed },
    });
  } else {
    goodsHandoverAdapter.removeOne(state, buyerId);
  }
};

// Helper function shared by reducer and extraReducer
const setCommentAssignmentHelper = (state, action) => {
  state.commentAssignment = action.payload;

  if (state.commentAssignment) {
    state.currentColumn = "details";
  } else if (!state.selectedAssignmentIds.length) {
    state.currentColumn = "lots";
  }
};

// Helper function shared by reducer and extraReducer
const setBuyerHelper = (state, action) => {
  state.selectedBuyerId = action.payload;

  if (state.selectedBuyerId) {
    state.currentColumn = "lots";
  } else {
    state.currentColumn = "buyers";
  }
};

export const goodsHandoverSlice = createSlice({
  name: "goodsHandover",
  initialState,
  reducers: {
    showForPickup: (state, action) => {
      state.forPickup = action.payload;
    },
    reset: (state, action) => initialState,
    clear: (state, action) => {
      goodsHandoverAdapter.removeAll(state);
      state.commentAssignment = null;
      state.selectedAssignmentIds = [];
      state.selectedBuyerId = null;
      state.dataLoaded = true;
      state.loading = false;
    },
    invalidateGoodsHandovers: (state, action) => {
      state.dataLoaded = false;
    },
    selectBuyer: (state, action) => {
      setBuyerHelper(state, action);
    },
    setBuyerQuery: (state, action) => {
      state.buyerQuery = action.payload;
    },
    setLotQuery: (state, action) => {
      state.lotQuery = action.payload;
    },
    setCommentAssignment: (state, action) => {
      setCommentAssignmentHelper(state, action);
    },
    toggleAssignmentId: (state, action) => {
      if (state.selectedAssignmentIds.includes(action.payload)) {
        state.selectedAssignmentIds = state.selectedAssignmentIds.filter(
          (selectedAssignmentId) => selectedAssignmentId !== action.payload
        );

        if (!state.selectedAssignmentIds.length) state.currentColumn = "lots";
      } else {
        state.selectedAssignmentIds = [
          action.payload,
          ...state.selectedAssignmentIds,
        ];
      }
    },
    selectAssignmentId: (state, action) => {
      if (state.selectedAssignmentIds.includes(action.payload)) {
        state.selectedAssignmentIds = state.selectedAssignmentIds.filter(
          (selectedAssignmentId) => selectedAssignmentId !== action.payload
        );
      } else {
        state.selectedAssignmentIds = [action.payload];
      }
    },
    setCurrentColumn: (state, action) => {
      state.currentColumn = action.payload;

      if (action.payload === "lots" && !state.forPickup)
        state.selectedAssignmentIds = [];
    },
    setCurrentDeliveredBy: (state, action) => {
      state.currentDeliveredBy = action.payload;
    },
    setTransporterMode: (state, action) => {
      state.transporterMode = action.payload;
    },
    setTransporterCompany: (state, action) => {
      state.transporterCompany = action.payload;
    },
    setTransporterLicensePlate: (state, action) => {
      state.transporterLicensePlate = action.payload;
    },
    setTransporterName: (state, action) => {
      state.transporterName = action.payload;
    },
    markAssignmentAsPaid: (state, action) => {
      const goodsHandover = state.entities[action.payload.buyerId];

      if (!goodsHandover) return;

      const assignments = goodsHandover.assignments.map((assignment) => {
        if (assignment.id === action.payload.id) {
          return { ...assignment, payment_status: "paid", changed: true };
        } else {
          return assignment;
        }
      });

      goodsHandoverAdapter.updateOne(state, {
        id: action.payload.buyerId,
        changes: { assignments: assignments, changed: true },
      });
    },
    markGoodsHandoverAsUnchanged: (state, action) => {
      const assignments = state.entities[action.payload].assignments.map(
        (assignment) => {
          return { ...assignment, changed: false };
        }
      );

      goodsHandoverAdapter.updateOne(state, {
        id: action.payload,
        changes: { assignments: assignments, changed: false },
      });
    },
    removeDeliveredAssignment: (state, action) => {
      state.selectedAssignmentIds = state.selectedAssignmentIds.filter(
        (assignment) => assignment.id !== action.payload.id
      );

      removeBuyerDeliveredAssignments(
        state,
        action.payload.buyerId,
        [action.payload.id],
        true
      );
    },
  },
  extraReducers: {
    [fetchAllGoodsHandover.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllGoodsHandover.fulfilled]: (state, action) => {
      goodsHandoverAdapter.setAll(state, action);

      state.loading = false;
      state.dataLoaded = true;
    },
    [fetchAllGoodsHandover.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveCommentAssignment.fulfilled]: (state, action) => {
      const { id, issueType, comments } = action.payload;
      const existingAssignment = state.entities[
        state.selectedBuyerId
      ].assignments.find((assignment) => assignment.id === id);
      if (existingAssignment) {
        existingAssignment.issue_type = issueType;
        existingAssignment.comments = comments;
      }

      setCommentAssignmentHelper(state, { payload: null });
    },
    [deliverAssignments.pending]: (state, action) => {
      state.delivering = true;
    },
    [deliverAssignments.rejected]: (state, action) => {
      state.delivering = false;
    },
    [deliverAssignments.fulfilled]: (state, action) => {
      removeDeliveredAssignmentsFromGoodsHandovers(state, action);
      setBuyerHelper(state, { payload: null });
      state.delivering = false;
    },
    [enqueueOfflineDelivery.fulfilled]: (state, action) => {
      removeDeliveredAssignmentsFromGoodsHandovers(state, action);
    },
    [saveIdProof.pending]: (state, action) => {
      state.idProofUploading = true;
    },
    [saveIdProof.rejected]: (state, action) => {
      state.idProofUploading = false;
    },
    [saveIdProof.fulfilled]: (state, action) => {
      const { buyerIds, url, signed_id } = action.payload;

      buyerIds.forEach((buyerEntityId) => {
        goodsHandoverAdapter.updateOne(state, {
          id: buyerEntityId,
          changes: { id_proof: { url, signed_id } },
        });
      });

      state.idProofUploading = false;
    },
    [syncOfflineDeliveries.pending]: (state, action) => {
      state.syncingOfflineDeliveries = true;
    },
    [syncOfflineDeliveries.fulfilled]: (state, action) => {
      removeDeliveredAssignmentsFromGoodsHandovers(state, action);

      state.syncingOfflineDeliveries = false;
    },
  },
});

export const {
  selectAll: selectAllGoodsHandover,
  selectById: selectGoodsHandoverById,
} = goodsHandoverAdapter.getSelectors((state) => state.goodsHandover);

export const goodsHandoverLoading = (state) => state.goodsHandover.loading;
export const goodsHandoverForPickup = (state) => state.goodsHandover.forPickup;
export const selectSelectedBuyerId = (state) =>
  state.goodsHandover.selectedBuyerId;

export const selectSelectedGoodsHandovers = createSelector(
  [
    selectAllGoodsHandover,
    (state) => state.goodsHandover.selectedAssignmentIds,
  ],
  (goodsHandovers, assignmentIds) =>
    goodsHandovers.filter((goodsHandover) =>
      goodsHandover.assignments.some((assignment) =>
        assignmentIds.includes(assignment.id)
      )
    )
);

export const selectSelectedIdProof = createSelector(
  [selectSelectedGoodsHandovers],
  (goodsHandovers) =>
    goodsHandovers
      .map((goodsHandover) => goodsHandover?.id_proof)
      .filter(Boolean)[0]
);

export const filteredGoodsHandoversByAuctionId = createSelector(
  [selectAllGoodsHandover, (state, selectedAuctionId) => selectedAuctionId],
  (goodsHandovers, auctionId) =>
    goodsHandovers.filter(
      (goodsHandover) =>
        !auctionId ||
        goodsHandover.assignments.some(
          (assignment) => assignment.auction.id === auctionId
        )
    )
);

export const filteredGoodsHandoversByBuyerQuery = createSelector(
  [
    filteredGoodsHandoversByAuctionId,
    (state) => state.goodsHandover.buyerQuery,
  ],
  (goodsHandovers, query) =>
    goodsHandovers.filter((goodsHandover) => {
      const matchesUserName = goodsHandover.buyer_entity.user_name
        .toLowerCase()
        .includes(query.toLowerCase());

      const matchesEmail = goodsHandover.buyer_entity.email
        .toLowerCase()
        .includes(query.toLowerCase());

      const matchesCompanyName = goodsHandover.buyer_entity.company_name
        .toLowerCase()
        .includes(query.toLowerCase());

      return matchesUserName || matchesEmail || matchesCompanyName;
    })
);

export const filteredCurrentAssignmentsByLotQuery = createSelector(
  [
    (state) => state.goodsHandover.entities,
    selectSelectedBuyerId,
    (state) => state.goodsHandover.lotQuery,
    (state, currentLanguage) => currentLanguage,
  ],
  (goodsHandovers, selectedBuyerId, query, currentLanguage) =>
    (goodsHandovers[selectedBuyerId]?.assignments || []).filter(
      (assignment) => {
        const matchesLotNumber = assignment.lot.number
          .toString()
          .includes(query);
        const matchesName = assignment.lot.name_translations[currentLanguage]
          .toLowerCase()
          .includes(query.toLowerCase());

        return matchesLotNumber || matchesName;
      }
    )
);

export const selectSelectedAssignments = createSelector(
  [
    (state) => state.goodsHandover.entities,
    (state) => state.goodsHandover.selectedAssignmentIds,
  ],
  (goodsHandovers, selectedAssignmentIds) =>
    Object.values(goodsHandovers)
      .flatMap((value) => value.assignments)
      .filter((assignment) => {
        return selectedAssignmentIds.includes(assignment.id);
      })
);

export const selectValidTransporter = createSelector(
  [
    (state) => state.goodsHandover.transporterMode,
    (state) => state.goodsHandover.transporterCompany,
    (state) => state.goodsHandover.transporterLicensePlate,
    (state) => state.goodsHandover.transporterName,
  ],
  (transporterMode, Company, LicensePlate, Name) => {
    if (transporterMode) {
      return Company !== "" && LicensePlate !== "" && Name !== "";
    } else {
      return true;
    }
  }
);

export const {
  selectBuyer,
  setBuyerQuery,
  setLotQuery,
  toggleAssignmentId,
  selectAssignmentId,
  setCommentAssignment,
  clear,
  invalidateGoodsHandovers,
  reset,
  showForPickup,
  selectDeliveredAssignmentId,
  setCurrentColumn,
  setCurrentDeliveredBy,
  setTransporterMode,
  setTransporterCompany,
  setTransporterLicensePlate,
  setTransporterName,
  markAssignmentAsPaid,
  markGoodsHandoverAsUnchanged,
  removeDeliveredAssignment,
} = goodsHandoverSlice.actions;

export default goodsHandoverSlice.reducer;
