import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FormField, Button, ConfirmationModal } from "vavato-ui";

import DateTimePicker from "./DateTimePicker";
import CountryDropdown from "./CountryDropdown";

import {
  saveDebrief,
  selectGroupedLotsForDebrief,
} from "../store/auctionsSlice";

import { selectCurrentLanguage, selectUserRoles } from "../store/profileSlice";

import { LOGISTICS_ROLE } from "../constants";

const Form = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  position: relative;
`;

const DatesInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;

  > div {
    flex: 0 auto;
  }
`;

const AddressesInputContainer = styled.div`
  margin-bottom: 72px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 32px;
  margin-right: 32px;

  & button {
    margin: 0;
  }
`;

const MainLabelContainer = styled.div`
  display: block;
  margin-left: 32px;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.tableHeaders};
`;

const FixedWidthLabel = styled(Label)`
  width: 115px;
`;

const DebriefForm = () => {
  const { t } = useTranslation("debrief");

  const currentLanguage = useSelector(selectCurrentLanguage);
  const userRoles = useSelector(selectUserRoles);
  const groupedLots = useSelector(selectGroupedLotsForDebrief);

  const dispatch = useDispatch();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const releaseFromRef = useRef(null);
  const releaseToRef = useRef(null);
  const commentsRef = useRef(null);
  const releaseLocationAddressLine1Ref = useRef(null);
  const releaseLocationAddressLine2Ref = useRef(null);
  const releaseLocationCityRef = useRef(null);
  const releaseLocationZipRef = useRef(null);
  const releaseLocationCountryRef = useRef(null);

  const history = useHistory();

  const redirectToAuction = (auctionId) => {
    history.push(`/${currentLanguage}/auctions?auctionId=${auctionId}`);
  };

  const hasUndeliveredLots = () => {
    return !!groupedLots.undelivered.length;
  };

  const rejectEmptyStrings = (obj) => {
    const result = {};

    Object.keys(obj).forEach((key) => {
      if (obj[key] !== "") {
        result[key] = obj[key];
      }
    });

    return result;
  };

  const handleSendClick = () => {
    if (
      !releaseFromRef.current?.value &&
      !releaseToRef.current?.value &&
      hasUndeliveredLots()
    ) {
      setConfirmationModalOpen(true);
    } else {
      submitDebrief();
    }
  };

  const submitDebrief = () => {
    dispatch(
      saveDebrief(
        rejectEmptyStrings({
          releaseFrom: releaseFromRef.current?.value,
          releaseTo: releaseToRef.current?.value,
          releaseLocation: rejectEmptyStrings({
            address_line_1: releaseLocationAddressLine1Ref.current?.value,
            address_line_2: releaseLocationAddressLine2Ref.current?.value,
            city: releaseLocationCityRef.current?.value,
            zip: releaseLocationZipRef.current?.value,
            country: releaseLocationCountryRef.current?.value,
          }),
          comments: commentsRef.current?.value,
          redirectToAuction,
        })
      )
    );
  };

  return (
    <Form>
      <MainLabelContainer>
        <Label>{t("nextReleaseLabel")}</Label>
      </MainLabelContainer>
      <DatesInputContainer>
        <FormField>
          <Label htmlFor="releaseFrom">{t("from")}</Label>
          <DateTimePicker
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseFrom"
            ref={releaseFromRef}
          />
        </FormField>
        <FormField>
          <Label htmlFor="releaseTo">{t("to")}</Label>
          <DateTimePicker
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseTo"
            ref={releaseToRef}
          />
        </FormField>
      </DatesInputContainer>
      <MainLabelContainer>
        <Label>{t("nextReleaseLocation")}</Label>
      </MainLabelContainer>
      <AddressesInputContainer>
        <FormField>
          <FixedWidthLabel htmlFor="releaseLocationAddressLine1">
            {t("releaseLocationLabels.addressLine1")}
          </FixedWidthLabel>
          <input
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseLocationAddressLine1"
            type="text"
            ref={releaseLocationAddressLine1Ref}
          />
        </FormField>
        <FormField>
          <FixedWidthLabel htmlFor="releaseLocationAddressLine2">
            {t("releaseLocationLabels.addressLine2")}
          </FixedWidthLabel>
          <input
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseLocationAddressLine2"
            type="text"
            ref={releaseLocationAddressLine2Ref}
          />
        </FormField>
        <FormField>
          <FixedWidthLabel htmlFor="releaseLocationCity">
            {t("releaseLocationLabels.city")}
          </FixedWidthLabel>
          <input
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseLocationCity"
            type="text"
            ref={releaseLocationCityRef}
          />
        </FormField>
        <FormField>
          <FixedWidthLabel htmlFor="releaseLocationZip">
            {t("releaseLocationLabels.zip")}
          </FixedWidthLabel>
          <input
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseLocationZip"
            type="text"
            ref={releaseLocationZipRef}
          />
        </FormField>
        <FormField>
          <FixedWidthLabel htmlFor="releaseLocationCountry">
            {t("releaseLocationLabels.country")}
          </FixedWidthLabel>
          <CountryDropdown
            disabled={userRoles.includes(LOGISTICS_ROLE)}
            name="releaseLocationCountry"
            placeholder={t("countryPlaceholder")}
            ref={releaseLocationCountryRef}
          />
        </FormField>
      </AddressesInputContainer>
      <FormField name="comment" label={t("commentsLabel")}>
        <textarea name="comment" rows="6" ref={commentsRef} />
      </FormField>
      <ButtonGroup>
        <Button onClick={() => handleSendClick()}>{t("submit")}</Button>
      </ButtonGroup>
      <ConfirmationModal
        modalOpen={confirmationModalOpen}
        setModalOpen={setConfirmationModalOpen}
        title={t("confirmModalTitle")}
        description={t("confirmModalDescription")}
        confirm={() => submitDebrief()}
      ></ConfirmationModal>
    </Form>
  );
};

export default DebriefForm;
