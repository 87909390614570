import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  faTimesCircle,
  faInfoCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Toaster, ToasterContext } from "vavato-ui";

import { selectAllToasters } from "../store/toasterSlice";

import { MOBILE_NAVIGATION_WIDTH } from "../constants";

const ICONS = {
  success: faCheckCircle,
  error: faTimesCircle,
  info: faInfoCircle,
};

const ToastersDisplay = styled("div")`
  position: fixed;
  bottom: 10%;
  right: 10%;
  left: calc(${(props) => props.theme.navbarWidth}px + 10%);
  z-index: 999;

  &.mobile {
    left: 10%;
  }
`;

function ToasterViewport() {
  const { toasters, dismiss } = useContext(ToasterContext);
  const reduxToasters = useSelector(selectAllToasters);

  const allToasters = useMemo(() => {
    return [...reduxToasters, ...toasters];
  }, [toasters, reduxToasters]);

  function renderToaster({ type, key, message }) {
    return (
      <Toaster
        type={type}
        icon={ICONS[type]}
        key={key}
        uid={key}
        onDismiss={() => dismiss(key)}
      >
        {message}
      </Toaster>
    );
  }
  return (
    <ToastersDisplay
      className={window.innerWidth <= MOBILE_NAVIGATION_WIDTH ? "mobile" : ""}
    >
      {allToasters.map((entry) => renderToaster(entry))}
    </ToastersDisplay>
  );
}

export default ToasterViewport;
