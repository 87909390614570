import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { faFileExport, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Breadcrumbs,
  FormField,
  Button,
  DropdownButton,
  LinkWithIcon,
  Loader,
  formatDate,
  formatDateOnly,
} from "vavato-ui";
import Stepper from "../components/Stepper";
import AuctionContainer from "../components/AuctionContainer";
import { AUCTION_STATES } from "../constants";

import { currentImpersonatedSeller } from "../store/impersonateSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

import {
  fetchAuctions,
  fetchLots,
  selectAllAuctions,
  auctionSelected,
  selectLots,
  selectSelectedAuction,
  exportLots,
  exportInvoices,
} from "../store/auctionsSlice";

const Page = styled.main`
  padding: 16px;
  width: 100%;
`;

const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  & h1 {
    margin: 0;
    padding 0 16px;
  }
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: middle;
  padding: 0 32px 16px 32px;
`;

const AuctionsOverview = ({ setTitle }) => {
  const { t } = useTranslation("auctions");

  useEffect(() => setTitle(t("auctions")));

  const dispatch = useDispatch();

  const auctions = useSelector(selectAllAuctions);
  const currentAuction = useSelector(selectSelectedAuction);
  const lots = useSelector(selectLots);

  const impersonatedSeller = useSelector(currentImpersonatedSeller);

  const currentLanguage = useSelector(selectCurrentLanguage);

  useEffect(() => {
    dispatch(fetchAuctions());
  }, [dispatch, impersonatedSeller]);

  useEffect(() => {
    if (!currentAuction) return;

    dispatch(fetchLots({ auctionId: currentAuction.id }));
  }, [dispatch, currentAuction]);

  const generateGoodsHandoverLink = (auction) => {
    if (!auction) return;

    const queryParams = new URLSearchParams();
    queryParams.append("auctionId", auction.id);

    const releaseFrom = moment.utc(auction.release_from);
    const releaseTo = moment.utc(auction.release_to);

    const startOfToday = moment().startOf("day");
    const endOfToday = moment().endOf("day");
    const lowerLimit = moment().subtract(60, "days").startOf("day");
    const upperLimit = moment().add(60, "days").endOf("day");

    if (releaseFrom <= endOfToday && releaseTo >= lowerLimit) {
      queryParams.append("releaseDate", "last_60_days");
    } else if (releaseFrom >= startOfToday && releaseTo <= endOfToday) {
      queryParams.append("releaseDate", "today");
    } else if (releaseTo > endOfToday && releaseFrom <= upperLimit) {
      queryParams.append("releaseDate", "next_60_days");
    } else {
      return;
    }

    return `/${currentLanguage}/?${queryParams.toString()}`;
  };

  const goodsHandoverLink = generateGoodsHandoverLink(currentAuction);

  const hasLotsForDelivery = lots.find(
    (el) =>
      el.assignment &&
      el.assignment.status !== "credited" &&
      el.assignment.status !== "delivered"
  );

  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    dispatch(auctionSelected(+queryParams.get("auctionId")));
  }, [history.location.search, dispatch]);

  const setCurrentAuction = (e) => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.set("auctionId", e.target.value);

    history.push(
      [history.location.pathname, queryParams.toString()]
        .filter((el) => el.length)
        .join("?")
    );

    dispatch(auctionSelected(e.target.value));
  };

  return (
    <Page>
      <Breadcrumbs>
        <a href="/">{t("sales")}</a>
        <span className="active">{t("auctions")}</span>
      </Breadcrumbs>
      <TitleWrapper>
        <h1>{t("title")}</h1>
        <FormField>
          <select onChange={setCurrentAuction} value={currentAuction?.id}>
            {auctions.map((a) => (
              <option key={a.id} value={a.id}>
                {a.id} - {a.name_translations[currentLanguage]}
              </option>
            ))}
          </select>
          <Button
            className="outline"
            disabled={!currentAuction}
            onClick={() =>
              dispatch(fetchLots({ auctionId: currentAuction.id }))
            }
          >
            {t("refresh")}
          </Button>
          {hasLotsForDelivery && goodsHandoverLink ? (
            <a href={goodsHandoverLink}>
              <Button>{t("startGoodsHandover")}</Button>
            </a>
          ) : (
            <Button disabled>{t("startGoodsHandover")}</Button>
          )}
          <DropdownButton disabled={!currentAuction} label={t("export")}>
            <LinkWithIcon
              icon={faFileExport}
              onClick={() =>
                dispatch(exportLots({ auctionId: currentAuction.id }))
              }
            >
              {t("exportLotsAsCsv")}
            </LinkWithIcon>
            <LinkWithIcon
              icon={faFilePdf}
              onClick={() =>
                dispatch(exportInvoices({ auctionId: currentAuction.id }))
              }
            >
              {t("exportInvoicesAsPdf")}
            </LinkWithIcon>
          </DropdownButton>
        </FormField>
      </TitleWrapper>
      <Content>
        <Stepper
          currentStep={
            currentAuction ? AUCTION_STATES.indexOf(currentAuction.status) : -1
          }
          steps={
            currentAuction
              ? [
                  {
                    content: "1",
                    title: t("created"),
                    subtitle: formatDateOnly(currentAuction.created_at),
                  },
                  {
                    content: "2",
                    title: t("announced"),
                    subtitle: formatDate(currentAuction.visible_date),
                  },
                  {
                    content: "3",
                    title: t("open"),
                    subtitle: formatDate(currentAuction.start_date),
                  },
                  {
                    content: "4",
                    title: t("closed"),
                    subtitle: formatDate(currentAuction.end_date),
                  },
                  {
                    content: "5",
                    title: t("settled"),
                    subtitle: formatDate(currentAuction.hide_date),
                  },
                ]
              : []
          }
        />

        {currentAuction ? (
          <AuctionContainer auction={currentAuction} />
        ) : (
          <Loader big active />
        )}
      </Content>
    </Page>
  );
};

export default AuctionsOverview;
