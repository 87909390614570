import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table, formatDate, formatDateOnly } from "vavato-ui";

import { loadingAuction, selectSelectedAuction } from "../store/auctionsSlice";

const AuctionDatesTable = () => {
  const { t } = useTranslation("auctions");
  const loading = useSelector(loadingAuction);
  const auction = useSelector(selectSelectedAuction);

  if (loading) return null;

  const releaseDate = [
    ...new Set([
      formatDate(auction.release_to),
      formatDate(auction.release_from),
    ]),
  ].join(" - ");

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("dates")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("creationDate")}</td>
          <td>{formatDateOnly(auction.created_at)}</td>
        </tr>
        <tr>
          <td>{t("announcementDate")}</td>
          <td>{formatDate(auction.visible_date)}</td>
        </tr>
        <tr>
          <td>{t("openingDate")}</td>
          <td>{formatDate(auction.start_date)}</td>
        </tr>
        <tr>
          <td>{t("closingDate")}</td>
          <td>{formatDate(auction.end_date)}</td>
        </tr>
        <tr>
          <td>{t("releaseDate")}</td>
          <td>{releaseDate}</td>
        </tr>
        <tr>
          <td>{t("settlementDate")}</td>
          <td>{formatDate(auction.hide_date)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AuctionDatesTable;
