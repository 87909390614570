import { Label } from "vavato-ui";

export const lotStatusLabel = (status, t) => {
  var className;
  if (status === "open") {
    className = "success";
  } else if (status === "closed") {
    className = "error";
  } else {
    className = "info";
  }

  return <Label className={className}>{t(`lot.status.${status}`)}</Label>;
};

export const assignmentStatusLabel = (status, t) => {
  var className;
  if (status === "paid") {
    className = "success";
  } else if (status === "delivered") {
    className = "primary";
  } else if (status === "pro_forma" || status === "not_invoiced") {
    className = "error";
  } else if (status === "credited") {
    className = "secondary";
  } else {
    className = "info";
  }

  return (
    <Label className={className}>{t(`assignment.status.${status}`)}</Label>
  );
};

export const totalValue = (lots, field) => {
  return lots.reduce((prev, current) => prev + (current[field] || 0), 0);
};
