import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Loader } from "vavato-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { rgba } from "polished";

const InputContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

const Heading = styled.h5`
  color: ${(props) => props.theme.tableHeaders} !important;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  border: 1px dashed ${(props) => props.theme.secondary};
  height: 160px;

  &:hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }

  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-start;
  margin-left: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  background-color: ${(props) => rgba(props.theme.secondary, 0.2)};
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 15px;
`;

const CameraInput = forwardRef((props, ref) => {
  const { t } = useTranslation("goodsHandover");

  const idProofUploading = useSelector(
    (state) => state.goodsHandover.idProofUploading
  );

  return (
    <InputContainer>
      <Heading>{t("camera.heading")}</Heading>
      {idProofUploading ? (
        <Box>
          <Loader active big />
        </Box>
      ) : (
        <>
          <Box
            onDragOver={props.onDragOver}
            onDrop={props.onDrop}
            onClick={props.onClick}
          >
            {props.image ? (
              <img src={props.image} alt={t("camera.alternativeText")}></img>
            ) : (
              <>
                <Icon icon={faCamera} size="2x"></Icon>
                <div>{t("camera.description")}</div>
              </>
            )}
            {props.children}
          </Box>
          {props.image && props.onClick ? (
            <StyledButton className="outline" onClick={props.onClick}>
              {t("tryAgain")}
            </StyledButton>
          ) : (
            ""
          )}
        </>
      )}
    </InputContainer>
  );
});

export default CameraInput;
