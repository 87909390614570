import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "vavato-ui";

import {
  loadingAuction,
  selectGroupedLotsForDebrief,
} from "../store/auctionsSlice";

const LotCommentsStatsTable = () => {
  const { t } = useTranslation("debrief");

  const loading = useSelector(loadingAuction);
  const groupedLots = useSelector(selectGroupedLotsForDebrief);

  if (loading) return null;

  const lotsUnpaidWithComments = groupedLots.unpaidWithComments.length;
  const lotsUnpaidWithoutCommentsCount =
    groupedLots.unpaidWithoutComments.length;
  const lotsUndeliveredCount = groupedLots.undelivered.length;
  const lotsDeliveredWithCommentsCount = groupedLots.withComments.length;
  const lotsDeliveredWithoutCommentsCount = groupedLots.withoutComments.length;

  const totalCount =
    lotsUnpaidWithComments +
    lotsUnpaidWithoutCommentsCount +
    lotsUndeliveredCount +
    lotsDeliveredWithCommentsCount +
    lotsDeliveredWithoutCommentsCount;

  const percentage = (count) => {
    return totalCount ? Math.round((count / totalCount) * 100.0) : 0;
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("lotStatuses.title")}</th>
          <th className="number">{t("total")}</th>
          <th className="number">{t("percentage")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("lotStatuses.withComments")}</td>
          <td className="number">{lotsDeliveredWithCommentsCount}</td>
          <td className="number">
            {percentage(lotsDeliveredWithCommentsCount)}
          </td>
        </tr>
        <tr>
          <td>{t("lotStatuses.unpaidWithComments")}</td>
          <td className="number">{lotsUnpaidWithComments}</td>
          <td className="number">{percentage(lotsUnpaidWithComments)}</td>
        </tr>
        <tr>
          <td>{t("lotStatuses.unpaidWithoutComments")}</td>
          <td className="number">{lotsUnpaidWithoutCommentsCount}</td>
          <td className="number">
            {percentage(lotsUnpaidWithoutCommentsCount)}
          </td>
        </tr>
        <tr>
          <td>{t("lotStatuses.undelivered")}</td>
          <td className="number">{lotsUndeliveredCount}</td>
          <td className="number">{percentage(lotsUndeliveredCount)}</td>
        </tr>
        <tr>
          <td>{t("lotStatuses.withoutComments")}</td>
          <td className="number">{lotsDeliveredWithoutCommentsCount}</td>
          <td className="number">
            {percentage(lotsDeliveredWithoutCommentsCount)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default LotCommentsStatsTable;
