import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { queryParams } from "vavato-ui";
import { fetchData } from "../api-service";

import { error } from "./toasterSlice";

import { handleErrors } from "../utils/api";

const goodsHandoverAdminAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

const initialState = goodsHandoverAdminAdapter.getInitialState({
  dataLoaded: false,
  loading: true,
});

export const fetchAuctions = createAsyncThunk(
  "goodsHandoverAdmin/fetchAuctions",
  async (timeInterval, { getState, dispatch }) => {
    const { canImpersonate } = getState().profile;

    if (!canImpersonate) return [];

    const query = queryParams({
      release_date: timeInterval,
    });
    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(`${domain}/seller/auctions${query}`);

    handleErrors(result, "Error fetching auctions", dispatch, error);
    return result.json();
  }
);

export const goodsHandoverAdminSlice = createSlice({
  name: "goodsHandoverAdmin",
  initialState,
  reducers: {
    invalidateGoodsHandoversAdmin: (state, action) => {
      state.dataLoaded = false;
    },
    adminLoaded: (state, action) => {
      state.loading = false;
      state.dataLoaded = true;
    },
  },
  extraReducers: {
    [fetchAuctions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAuctions.fulfilled]: (state, action) => {
      goodsHandoverAdminAdapter.setAll(state, action);
      state.loading = false;
      state.dataLoaded = true;
    },
    [fetchAuctions.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  selectAll: selectAllGoodsHandoverAuctions,
  selectById: selectGoodsHandoverAuctionsById,
} = goodsHandoverAdminAdapter.getSelectors((state) => state.goodsHandoverAdmin);

export const goodsHandoverAdminLoading = (state) =>
  state.goodsHandoverAdmin.loading;

export const { invalidateGoodsHandoversAdmin, adminLoaded } =
  goodsHandoverAdminSlice.actions;

export default goodsHandoverAdminSlice.reducer;
