import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const BidderName = styled.div`
  margin-left: 10px;
`;

const LotBidder = ({ bidderName, countryCode, countryName }) => {
  return (
    <Container>
      <ReactCountryFlag
        svg
        countryCode={countryCode}
        aria-label={countryName}
        title={countryName}
        style={{ width: "2em", height: "2em" }}
      />
      <BidderName>{bidderName}</BidderName>
    </Container>
  );
};

export default LotBidder;
