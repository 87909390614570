import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Loader } from "vavato-ui";

import OpenLotsTable from "../components/OpenLotsTable";

import { loadingAuction, selectLots } from "../store/auctionsSlice";

const OpenLotsTableContainer = () => {
  const { t } = useTranslation("auctions");

  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  if (loading) return <Loader big active />;

  const groupedLots = lots.reduce(
    (lots, lot) => {
      if (!lot.bid_price_in_euro) {
        lots.withoutBids.push(lot);
      } else if (lot.bid_price_in_euro >= lot.expected_sale_price) {
        lots.aboveMinimum.push(lot);
      } else {
        lots.belowMinimum.push(lot);
      }

      return lots;
    },
    { withoutBids: [], aboveMinimum: [], belowMinimum: [] }
  );

  return (
    <>
      <OpenLotsTable
        lots={groupedLots.aboveMinimum.sort(
          (a, b) => a.bids_count - b.bids_count
        )}
        title={t("lotsAboveMinimumPrice", {
          total: groupedLots.aboveMinimum.length,
        })}
      />
      <OpenLotsTable
        lots={groupedLots.belowMinimum.sort(
          (a, b) => a.bids_count > b.bids_count
        )}
        title={t("lotsBelowMinimumPrice", {
          total: groupedLots.belowMinimum.length,
        })}
      />
      <OpenLotsTable
        lots={groupedLots.withoutBids}
        title={t("lotsWithoutBids", { total: groupedLots.withoutBids.length })}
      />
    </>
  );
};

export default OpenLotsTableContainer;
