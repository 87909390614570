import { useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { rgba } from "polished";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { setCommentAssignment } from "../store/goodsHandoverSlice";

const CommentSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -5px;
  width: 100%;
  padding: 5px 0px;
  background-color: ${(props) => props.theme.lightBackground};
  position: relative;

  &.centered {
    justify-content: center;
  }
`;

const CommentLink = styled.span`
  cursor: pointer;
  text-decoration-line: underline;
  color: #6192a1;
  line-height: 16px;
`;

const Comment = styled.div`
  color: ${(props) => rgba(props.theme.text, 0.8)};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 10px;
  font-style: italic;
  text-transform: none;
  margin-top: 5px;
`;

const IssueType = styled.span`
  font-weight: bold;
`;

const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 0;
  color: ${(props) => props.theme.text};
`;

const CommentContainer = ({ assignment }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();
  const commentToggler = useRef();

  const setCommentTogglerPosition = useCallback(() => {
    if (!commentToggler.current) return;

    const parentElement = commentToggler.current.parentElement;
    const comment = parentElement.querySelector(Comment);

    const { width: totalWidth } = parentElement.getBoundingClientRect();
    const { width: titleWidth } = comment.getBoundingClientRect();
    const { width: elementWidth } =
      commentToggler.current.getBoundingClientRect();

    if (totalWidth !== titleWidth) {
      commentToggler.current.style.right = `${
        totalWidth - titleWidth - elementWidth
      }px`;
    }
  }, [commentToggler]);

  useEffect(() => {
    setCommentTogglerPosition();
  }, [setCommentTogglerPosition]);

  const commentTitle = [assignment.issue_type, assignment.comments]
    .filter((elem) => elem)
    .join(". ");

  return (
    <>
      {commentTitle.length ? (
        <CommentSection>
          <EditIcon ref={commentToggler}>
            <FontAwesomeIcon
              icon={faPencilAlt}
              onClick={() => dispatch(setCommentAssignment(assignment))}
            />
          </EditIcon>
          <Comment title={commentTitle}>
            {assignment.issue_type?.length ? (
              <IssueType>{`${assignment.issue_type}. `}</IssueType>
            ) : (
              ""
            )}
            {assignment.comments?.length ? (
              <span>{assignment.comments}</span>
            ) : (
              ""
            )}
          </Comment>
        </CommentSection>
      ) : (
        <CommentSection className="centered">
          <CommentLink
            onClick={() => dispatch(setCommentAssignment(assignment))}
          >
            {t("comment.addLink")}
          </CommentLink>
        </CommentSection>
      )}
    </>
  );
};

export default CommentContainer;
