import styled from "styled-components";

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  text-align: center;
  height: 100%;
  max-width: 240px;
  margin: auto;

  && h3 {
    margin: 0;
    text-transform: none;
    font-weight: 400 !important;
    font-size: 0.9rem;
  }

  && p {
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: 400 !important;
    font-size: 0.7rem;
    line-height: 1.5rem;
  }
`;

export default EmptyState;
