import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button, Modal, ModalHeader, ModalBody } from "vavato-ui";
import Webcam from "react-webcam";
import { BrowserView, MobileView } from "react-device-detect";

import CameraInput from "../components/CameraInput";

import {
  saveIdProof,
  selectSelectedIdProof,
} from "../store/goodsHandoverSlice";
import { getBlob } from "../utils/ActiveJS";

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HiddenImageFileInput = styled.input.attrs({
  type: "file",
  accept: "image/*",
})`
  display: none;
`;

const StyledModalHeader = styled(ModalHeader)`
  @media only screen and (max-width: 768px) {
    & h1 {
      font-size: 12px;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  @media only screen and (max-width: 768px) {
    & video {
      width: 100%;
    }
  }
`;

const WebcamModal = ({ assignmentIdsByBuyerId, modalOpen, setModalOpen }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const [webcamAllowed, setWebcamAllowed] = useState(false);

  const webcamRef = useRef(null);

  const captureIdVerificationPhoto = async () => {
    const webcamCanvas = webcamRef.current.getCanvas();

    // Webcam not authorized in browser, canvas is null
    if (!webcamCanvas) return;

    const blob = await getBlob(webcamCanvas, "id");

    dispatch(saveIdProof({ assignmentIdsByBuyerId, file: blob }));
  };

  const closeModal = () => {
    setModalOpen(false);
    setWebcamAllowed(false);
  };

  if (!Object.keys(assignmentIdsByBuyerId).length) return null;

  return (
    <Modal modalOpen={modalOpen} closeModal={closeModal}>
      <StyledModalHeader>
        <h1>{t("camera.title")}</h1>
      </StyledModalHeader>
      <StyledModalBody>
        <Webcam
          ref={webcamRef}
          screenshotFormat={"image/jpeg"}
          onUserMedia={() => setWebcamAllowed(true)}
        ></Webcam>
        <ButtonGroup>
          <Button onClick={closeModal} className="outline">
            {t("cancel")}
          </Button>
          {webcamAllowed ? (
            <Button
              onClick={() => {
                captureIdVerificationPhoto();
                closeModal();
              }}
            >
              {t("accept")}
            </Button>
          ) : (
            ""
          )}
        </ButtonGroup>
      </StyledModalBody>
    </Modal>
  );
};

const CameraContainer = ({ assignmentIdsByBuyerId }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const fileInputField = useRef(null);

  const selectedIdProofUrl = useSelector(selectSelectedIdProof)?.url;

  const uploadFile = (file) => {
    dispatch(saveIdProof({ assignmentIdsByBuyerId, file }));
  };

  return (
    <>
      <BrowserView>
        <CameraInput
          image={selectedIdProofUrl}
          onClick={() => setModalOpen(true)}
          onDragOver={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onDrop={(event) => {
            event.preventDefault();
            event.stopPropagation();
            uploadFile(event.dataTransfer.files[0]);
          }}
        />
        <WebcamModal
          assignmentIdsByBuyerId={assignmentIdsByBuyerId}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </BrowserView>
      <MobileView>
        <CameraInput
          image={selectedIdProofUrl}
          onClick={() => fileInputField.current.click()}
        >
          <HiddenImageFileInput
            ref={fileInputField}
            onChange={(event) => uploadFile(event.target.files[0])}
          />
        </CameraInput>
      </MobileView>
    </>
  );
};

export default CameraContainer;
