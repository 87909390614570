import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faTimes,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Label, ButtonWithIcon } from "vavato-ui";

import CommentContainer from "./CommentContainer";

import {
  toggleAssignmentId,
  selectAssignmentId,
  goodsHandoverForPickup,
  setCurrentColumn,
} from "../store/goodsHandoverSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

const Item = styled.li`
  flex-direction: column;
  background: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;

  &.hidden {
    display: none;
  }
`;

const Image = styled.div`
  margin-right: 10px;
  flex-basis: 15%;
  cursor: pointer;

  & img {
    max-height: 75px;
    width: 100%;
    object-fit: contain;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
`;

const Headline = styled.div`
  position: relative;
  margin-right: 15px;
  line-height: 1.2em;
  max-height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.primary} !important;
  padding-right: 10px;

  & a {
    color: ${(props) => props.theme.primary} !important;
  }
`;

const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${(props) => props.theme.text};
`;

const InvoiceNumber = styled.span`
  color: ${(props) => props.theme.text};
  margin-bottom: 5px;
`;

const InvoiceContainer = styled.div`
  padding-bottom: 10px;
`;

const ReleaseLocation = styled.span`
  font-weight: 100;
  color: ${(props) => props.theme.text};
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: 8px;
  margin-top: 10px;
`;

const Status = styled.div`
  white-space: nowrap;
  margin-right: 8px;

  & > span {
    transition: background-color 1s;
    transition: color 1s;
  }

  &.changed > span {
    background-color: ${(props) => props.theme.primary} !important;
    color: ${(props) => props.theme.lightText} !important;
  }
`;

const Description = styled.div`
  margin-bottom: 10px;
  color: ${(props) => props.theme.text};
`;

const Action = styled.div`
  flex-basis: 10%;
  display: flex;
  align-items: center;
  justify-content: end;

  &.hidden {
    visibility: hidden;
  }
`;

const SelectButton = styled(ButtonWithIcon)`
  padding: 8px;
  line-height: 0;
  width: 12px;
  height: 12px;
`;

const RemoveButton = styled(SelectButton)`
  border-radius: 25px;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.darkBackground};
  padding: 4px;
`;

const LotItem = ({ assignment, showLotLightbox, summary }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();
  const descriptionToggler = useRef();

  const [showDescription, setShowDescription] = useState(false);

  const forPickup = useSelector(goodsHandoverForPickup);

  const selectedAssignmentIds = useSelector(
    (state) => state.goodsHandover.selectedAssignmentIds
  );

  const currentLanguage = useSelector(selectCurrentLanguage);

  const assignmentLabel = () => {
    let className;
    if (assignment.payment_status === "paid") {
      className = "success";
    } else if (assignment.payment_status === "delivered") {
      className = "primary";
    } else {
      className = "error";
    }
    return (
      <Label className={className}>
        {t(`payment_status.${assignment.payment_status}`)}
      </Label>
    );
  };

  const setDescriptionTogglerPosition = useCallback(() => {
    const parentElement = descriptionToggler.current.parentElement;
    const title = parentElement.querySelector("a");

    const { width: totalWidth } = parentElement.getBoundingClientRect();
    const { width: titleWidth } = title.getBoundingClientRect();
    const { width: elementWidth } =
      descriptionToggler.current.getBoundingClientRect();

    descriptionToggler.current.style.right = `${
      totalWidth - titleWidth - elementWidth - 10
    }px`;
  }, [descriptionToggler]);

  useEffect(() => {
    setDescriptionTogglerPosition();
  }, [setDescriptionTogglerPosition]);

  return (
    <Item key={assignment.id}>
      <Row>
        <Image>
          <img
            onClick={() => {
              showLotLightbox(assignment.lot);
            }}
            src={assignment.lot.image_urls[0]?.small}
            alt={t("lotAlternativeText")}
          />
        </Image>
        <TitleSection>
          <Headline>
            <Icon
              onClick={() => setShowDescription(!showDescription)}
              ref={descriptionToggler}
            >
              <FontAwesomeIcon
                icon={showDescription ? faChevronUp : faChevronDown}
              ></FontAwesomeIcon>
            </Icon>
            <a
              href={assignment.lot.url}
              title={assignment.lot.name_translations[currentLanguage]}
              rel="noopener noreferrer"
              target="_blank"
            >
              {assignment.auction.id}.{assignment.lot.number}
              <br />
              {assignment.lot.name_translations[currentLanguage]}
            </a>
          </Headline>
          {summary ? (
            ""
          ) : (
            <PaymentDetails>
              <Status className={assignment.changed ? "changed" : ""}>
                {assignmentLabel()}
              </Status>
              <div>€{assignment.price}</div>
            </PaymentDetails>
          )}
        </TitleSection>
        {summary ? (
          <>
            {forPickup && (
              <Action>
                <RemoveButton
                  icon={faTimes}
                  onClick={() => dispatch(toggleAssignmentId(assignment.id))}
                />
              </Action>
            )}
          </>
        ) : (
          <>
            {forPickup ? (
              <Action
                className={assignment.payment_status !== "paid" && "hidden"}
              >
                <SelectButton
                  icon={faPlus}
                  disabled={
                    selectedAssignmentIds.includes(assignment.id) ||
                    assignment.payment_status !== "paid"
                  }
                  onClick={() => dispatch(toggleAssignmentId(assignment.id))}
                />
              </Action>
            ) : (
              <Action>
                <SelectButton
                  icon={faSearch}
                  disabled={selectedAssignmentIds.includes(assignment.id)}
                  onClick={() => {
                    dispatch(selectAssignmentId(assignment.id));
                    dispatch(setCurrentColumn("details"));
                  }}
                />
              </Action>
            )}
          </>
        )}
      </Row>
      <Row className={showDescription ? "" : "hidden"}>
        <Description
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              assignment.lot.description_html_translations[currentLanguage]
            ),
          }}
        />
      </Row>
      {summary ? (
        ""
      ) : (
        <InvoiceContainer>
          <InvoiceNumber>
            {assignment.invoice_number}&nbsp;&nbsp;-&nbsp;&nbsp;
          </InvoiceNumber>
          <ReleaseLocation>{assignment.lot.release_location}</ReleaseLocation>
        </InvoiceContainer>
      )}
      <CommentContainer assignment={assignment} />
    </Item>
  );
};

export default LotItem;
