import { useTranslation } from "react-i18next";
import { Table } from "vavato-ui";
import styled from "styled-components";

import FoldableTable from "./FoldableTable";
import LotsWithCommentsTableRow from "./LotsWithCommentsTableRow";

const TwoRowsStripedTable = styled(Table)`
  & tbody tr:nth-child(odd) {
    background: #fff !important;
  }

  & tbody tr:nth-child(4n + 1) {
    background: ${(props) => props.theme.background} !important;
  }

  & tbody tr:nth-child(4n + 2) {
    background: ${(props) => props.theme.background} !important;
  }
`;

const LotsWithCommentsTable = ({ lots, title, startOpen = true }) => {
  const { t } = useTranslation("auctions");

  if (!lots.length) return null;

  return (
    <FoldableTable title={title} startOpen={startOpen}>
      <TwoRowsStripedTable>
        <thead>
          <tr>
            <th>{t("headers.lotNr")}</th>
            <th></th>
            <th>{t("headers.title")}</th>
            <th>{t("headers.category")}</th>
            <th>{t("headers.bidder")}</th>
            <th>{t("headers.phone")}</th>
            <th className="number">{t("headers.final")}</th>
            <th className="number">{t("headers.bids")}</th>
            <th className="number">{t("headers.paymentStatus")}</th>
          </tr>
        </thead>
        <tbody>
          {lots.map((lot) => (
            <LotsWithCommentsTableRow key={lot.id} lot={lot} />
          ))}
        </tbody>
      </TwoRowsStripedTable>
    </FoldableTable>
  );
};

export default LotsWithCommentsTable;
