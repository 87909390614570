import { useSelector } from "react-redux";
import styled from "styled-components";

import CameraInput from "../components/CameraInput";
import SignatureInput from "../components/SignatureInput";
import PickupSummary from "../components/PickupSummary";

import {
  selectSelectedBuyerId,
  selectGoodsHandoverById,
  selectSelectedAssignments,
} from "../store/goodsHandoverSlice";

const Container = styled.div`
  overflow-y: auto;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-right: -10px;
    margin-left: -10px;
  }
`;

const DeliveredColumn = ({ showLotLightbox }) => {
  const selectedBuyerId = useSelector(selectSelectedBuyerId);

  const selectedGoodsHandover = useSelector((state) =>
    selectGoodsHandoverById(state, selectedBuyerId)
  );
  const selectedAssignments = useSelector(selectSelectedAssignments);

  const idProof = selectedAssignments.find(
    (assignment) => assignment.id_proof
  )?.id_proof;
  const signature = selectedAssignments.find(
    (assignment) => assignment.signature
  )?.signature;

  return (
    <>
      <Container>
        <PickupSummary
          buyerEntity={selectedGoodsHandover?.buyer_entity}
          assignments={selectedAssignments}
          showLotLightbox={showLotLightbox}
        />
        {idProof ? <CameraInput image={idProof.url} /> : ""}
        {signature ? <SignatureInput image={signature.url} /> : ""}
      </Container>
    </>
  );
};

export default DeliveredColumn;
