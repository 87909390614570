import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Table, SearchField, Autocomplete, FormField } from "vavato-ui";
import { handleJwt } from "../api-service";
import { rgba } from "polished";

import EmptyState from "../components/EmptyState";
import ColumnSearchContainer from "../components/ColumnSearchContainer";
import BuyerRow from "../components/BuyerRow.js";
import ScrollableList from "../components/ScrollableList.js";

import EntityOption from "../components/EntityOption";

import { currentImpersonatedSeller } from "../store/impersonateSlice";

import { impersonateAbility } from "../store/profileSlice";

import {
  filteredGoodsHandoversByAuctionId,
  filteredGoodsHandoversByBuyerQuery,
  setBuyerQuery,
} from "../store/goodsHandoverSlice";

const StyledTable = styled(Table)`
  margin-top: 0px;

  tbody > tr,
  tbody > tr > td {
    border-bottom: 1px solid ${(props) => rgba(props.theme.info, 0.2)};
  }
`;

const InvertColorTr = styled.tr`
  display: none;
`;

const ColumnSearchBar = ({ hasGoodsHandovers }) => {
  const { t } = useTranslation("goodsHandover");

  const history = useHistory();
  const dispatch = useDispatch();

  const canImpersonate = useSelector(impersonateAbility);
  const impersonatedSeller = useSelector(currentImpersonatedSeller);
  const query = useSelector((state) => state.goodsHandover.buyerQuery);

  const buyerSelected = (buyer) => {
    const queryParams = new URLSearchParams(history.location.search);

    if (buyer) {
      queryParams.set("buyerId", buyer.id);
    } else {
      queryParams.delete("buyerId");
    }

    history.push(
      [history.location.pathname, queryParams.toString()]
        .filter((el) => el.length)
        .join("?")
    );
  };

  useEffect(() => dispatch(setBuyerQuery("")), [dispatch]);

  return (
    <ColumnSearchContainer header={t("searchBuyerTitle")}>
      {canImpersonate && !impersonatedSeller && !hasGoodsHandovers ? (
        <FormField inline>
          <Autocomplete
            url="/admin/buyer_entity/autocomplete"
            placeholder={t("searchBuyerPlaceholder")}
            onSelect={buyerSelected}
            resultRenderer={(entity) => <EntityOption entity={entity} />}
            extraReqHeaders={handleJwt()}
          />
        </FormField>
      ) : (
        <SearchField
          value={query}
          placeholder={t("searchBuyerPlaceholder")}
          onChange={(value) => dispatch(setBuyerQuery(value))}
        ></SearchField>
      )}
    </ColumnSearchContainer>
  );
};

const BuyersList = ({ selectedAuctionId }) => {
  const { t } = useTranslation("goodsHandover");

  const goodsHandovers = useSelector((state) =>
    filteredGoodsHandoversByAuctionId(state, selectedAuctionId)
  );
  const filteredGoodsHandovers = useSelector((state) =>
    filteredGoodsHandoversByBuyerQuery(state, selectedAuctionId)
  );
  return (
    <>
      <ColumnSearchBar hasGoodsHandovers={goodsHandovers.length} />
      {goodsHandovers.length ? (
        <ScrollableList className="buyers">
          <StyledTable>
            <tbody>
              <InvertColorTr />
              {filteredGoodsHandovers.map((goodsHandover) => (
                <BuyerRow
                  key={goodsHandover.buyer_entity.id}
                  goodsHandover={goodsHandover}
                />
              ))}
            </tbody>
          </StyledTable>
        </ScrollableList>
      ) : (
        <EmptyState>
          <h3>{t("emptyBuyersTitle")}</h3>
          <p>{t("emptyBuyersDescription")}</p>
          <p>{t("emptyBuyersDescription2")}</p>
        </EmptyState>
      )}
    </>
  );
};

export default BuyersList;
