import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { rgba } from "polished";
import ReactCountryFlag from "react-country-flag";

import {
  selectBuyer,
  selectSelectedBuyerId,
  goodsHandoverForPickup,
  markGoodsHandoverAsUnchanged,
} from "../store/goodsHandoverSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

const TableRow = styled.tr`
  transition: background-color 1s;
  background: white;
  color: ${(props) => props.theme.text};

  &:hover td {
    cursor: pointer;
    background-color: ${(props) => rgba(props.theme.secondary, 0.2)};
  }

  &.error * {
    color: ${(props) => props.theme.error} !important;
  }

  &&.changed {
    background-color: ${(props) => props.theme.primary} !important;
  }
`;

const CountryCell = styled.td`
  width: 50px;
`;

const LotsCount = styled.td`
  white-space: nowrap;
  text-transform: uppercase;

  && {
    font-family: "Merriweather Sans", sans-serif;
    padding: 0;
    padding-right: 15px;
  }
`;

const Icon = styled.span`
  padding: 10px;
`;

const UserName = styled.div`
  color: ${(props) => rgba(props.theme.text, 0.6)};
  margin-top: 5px;
  font-weight: 100;
`;

const BuyerRow = ({ goodsHandover }) => {
  const { t } = useTranslation("goodsHandover");

  const currentLanguage = useSelector(selectCurrentLanguage);

  const dispatch = useDispatch();

  const forPickup = useSelector(goodsHandoverForPickup);

  const selectedBuyerId = useSelector(selectSelectedBuyerId);

  const hasUnpaidLots = forPickup
    ? goodsHandover.assignments.filter((el) => el.payment_status !== "paid")
        .length > 0
    : false;

  const active = goodsHandover.buyer_entity.id === selectedBuyerId;

  const classNames = [
    active ? "active" : "",
    hasUnpaidLots ? "error" : "",
    goodsHandover.changed ? "changed" : "",
  ].join(" ");

  const isCompany = goodsHandover.buyer_entity.company_name.length;

  const nameAttribute = isCompany ? "company_name" : "user_name";

  setTimeout(() => {
    if (!goodsHandover.changed) return;

    dispatch(markGoodsHandoverAsUnchanged(goodsHandover.buyer_entity.id));
  }, 1000);

  return (
    <TableRow
      className={classNames}
      key={goodsHandover.buyer_entity.id}
      onClick={() => dispatch(selectBuyer(goodsHandover.buyer_entity.id))}
    >
      <CountryCell>
        <Icon>
          <ReactCountryFlag
            svg
            countryCode={goodsHandover.buyer_entity.country_code}
            aria-label={
              goodsHandover.buyer_entity.country_name_translations[
                currentLanguage
              ]
            }
            title={
              goodsHandover.buyer_entity.country_name_translations[
                currentLanguage
              ]
            }
            style={{ width: "2em", height: "2em" }}
          />
        </Icon>
      </CountryCell>
      <td>
        <div>{goodsHandover.buyer_entity[nameAttribute]}</div>
        {isCompany ? (
          <UserName>{goodsHandover.buyer_entity.user_name}</UserName>
        ) : (
          ""
        )}
      </td>
      <LotsCount className="number">
        {t("lotsCount", { count: goodsHandover.assignments.length })}
        <Icon>
          <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
        </Icon>
      </LotsCount>
    </TableRow>
  );
};

export default BuyerRow;
