import React from "react";
import styled from "styled-components";

import AuctionDatesTable from "../components/AuctionDatesTable";
import AuctionStatsTable from "../components/AuctionStatsTable";
import LotsTable from "../components/LotsTable";
import OpenAuctionStatsTable from "../components/OpenAuctionStatsTable";
import OpenAuctionBidsTable from "../components/OpenAuctionBidsTable";
import OpenLotsTableContainer from "../components/OpenLotsTableContainer";
import CloseLotsTableContainer from "../components/CloseLotsTableContainer";
import CloseAuctionBidsTable from "../components/CloseAuctionBidsTable";
import CloseAuctionStatsTable from "../components/CloseAuctionStatsTable";

const TwoTables = styled.div`
  flex: 1;
  display: flex;

  table {
    border: 2px solid ${(props) => props.theme.background};
    border-radius: 20px;
  }

  table tbody td {
    padding: 0 16px;
  }

  table:first-child {
    margin-right: 20px;
  }
`;

const OpenAuctionContainer = () => {
  return (
    <React.Fragment>
      <TwoTables>
        <OpenAuctionBidsTable />
        <OpenAuctionStatsTable />
      </TwoTables>
      <OpenLotsTableContainer />
    </React.Fragment>
  );
};

const CloseAuctionContainer = () => {
  return (
    <React.Fragment>
      <TwoTables>
        <CloseAuctionBidsTable />
        <CloseAuctionStatsTable />
      </TwoTables>
      <CloseLotsTableContainer />
    </React.Fragment>
  );
};

const ScheduledAuctionContainer = () => {
  return (
    <React.Fragment>
      <TwoTables>
        <AuctionStatsTable />
        <AuctionDatesTable />
      </TwoTables>
      <LotsTable />
    </React.Fragment>
  );
};

const AuctionContainer = ({ auction }) => {
  if (auction.status === "bidding_open") {
    return <OpenAuctionContainer />;
  } else if (
    auction.status === "bidding_closed" ||
    auction.status === "retired"
  ) {
    return <CloseAuctionContainer />;
  } else {
    return <ScheduledAuctionContainer />;
  }
};

export default AuctionContainer;
