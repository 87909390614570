import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import OnlineIndicator from "./OnlineIndicator";
import ImpersonationContainer from "../components/ImpersonationContainer";

import vavatoLogo from "../assets/vavato-logo.svg";
import hamburgerButton from "../assets/hamburger-button.svg";

import { selectCurrentLanguage } from "../store/profileSlice";

const HeaderLeftStyle = styled.div`
  display: flex;
  align-items: center;
`;

const MenuButtonStyle = styled.div`
  cursor: pointer;
`;

const MenuIconStyle = styled.img`
  padding-left: 22px;
  display: flex;
`;

const NavbarLogo = styled.img`
  margin-left: 20px;
  display: flex;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const NavbarContainer = styled.nav`
  width: 100%;
  height: 40px;
  background-color: #343a40;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavbarOptions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 22px;
`;

const PageTitle = styled.div`
  color: ${(props) => props.theme.background};
  font-size: 12px;
  text-transform: uppercase;
  display: none;

  @media only screen and (max-width: 768px) {
    display: initial;
  }
`;

const NavbarResponsiveImpersonateWrapper = styled.div`
  display: initial;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Help = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.background};
`;

const Navbar = ({ open, setOpen, title }) => {
  const vavatoDotCom = process.env.REACT_APP_VAVATO_WEBSITE;

  const currentLanguage = useSelector(selectCurrentLanguage);

  // TODO: Add manual for other languages
  const manualLinks = {
    en: "https://docs.google.com/document/d/1ps1i39_LTaCaAKJhJJ_TBzACYRtHB8EkV2okPILA8Ew",
    nl: "https://docs.google.com/document/d/1Z2XblmaEh5pPmYk39zO9Fpd9uSLM1oHkCyxqKqa5A0k",
    fr: "https://docs.google.com/document/d/1ps1i39_LTaCaAKJhJJ_TBzACYRtHB8EkV2okPILA8Ew",
  };

  return (
    <NavbarContainer>
      <HeaderLeftStyle>
        <MenuButtonStyle>
          <MenuIconStyle onClick={() => setOpen(!open)} src={hamburgerButton} />
        </MenuButtonStyle>
        <a href={vavatoDotCom}>
          <NavbarLogo src={vavatoLogo} />
        </a>
      </HeaderLeftStyle>
      <PageTitle>
        {title}
        <OnlineIndicator />
      </PageTitle>
      <NavbarOptions>
        <NavbarResponsiveImpersonateWrapper>
          <ImpersonationContainer />
        </NavbarResponsiveImpersonateWrapper>
        <a
          href={manualLinks[currentLanguage]}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Help icon={faQuestionCircle} />
        </a>
      </NavbarOptions>
    </NavbarContainer>
  );
};

export default Navbar;
