import { createSlice, createAction } from "@reduxjs/toolkit";

import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

export const impersonateSeller = createAction(
  "impersonate/impersonateSeller",
  (seller) => {
    if (seller) {
      logEvent(analytics, "impersonate", {
        seller: seller.name,
        seller_id: seller.id,
      });
    }
    return {
      payload: { seller: seller },
    };
  }
);

const initialState = { impersonatedSeller: null };

const impersonateSlice = createSlice({
  name: "impersonate",
  initialState,
  reducers: {},
  extraReducers: {
    [impersonateSeller]: (state, action) => {
      state.impersonatedSeller = action.payload.seller;

      window.localStorage.setItem(
        "impersonatedSeller",
        JSON.stringify(state.impersonatedSeller)
      );
    },
  },
});

export const currentImpersonatedSeller = (state) =>
  state.impersonate.impersonatedSeller;

export default impersonateSlice.reducer;
