import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 10px;
`;

const Header = styled.h4`
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ColumnSearchContainer = ({ searchKey, header, children }) => {
  return (
    <React.Fragment key={searchKey}>
      <Container>
        <Header>{header}</Header>
        {children}
      </Container>
    </React.Fragment>
  );
};

export default ColumnSearchContainer;
