import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EntityOption = ({ entity }) => {
  const icon = entity.profileable_type === "User" ? faUser : faBuilding;

  return (
    <span>
      <FontAwesomeIcon icon={icon} /> {entity.profileable_name}
    </span>
  );
};

export default EntityOption;
