import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Loader } from "vavato-ui";

import { currentImpersonatedSeller } from "../store/impersonateSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

import {
  auctionSelected,
  selectSelectedAuction,
  fetchAuctions,
  fetchLots,
} from "../store/auctionsSlice";

import LotCommentsStatsTable from "../components/LotCommentsStatsTable";
import BuyerLocationsStatsTable from "../components/BuyerLocationsStatsTable";
import DebriefLotsTablesContainer from "../components/DebriefLotsTablesContainer";
import DebriefForm from "../components/DebriefForm";

const Page = styled.main`
  padding: 32px;
  width: 100%;
`;

const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  & h1 {
    margin: 0;
    padding 16px 0;
  }
`;

const TwoTables = styled.div`
  flex: 1;
  display: flex;

  table {
    border: 2px solid ${(props) => props.theme.background};
    border-radius: 20px;
  }

  table tbody td {
    padding: 0 16px;
  }

  table:first-child {
    margin-right: 20px;
  }
`;

const Debrief = ({ setTitle }) => {
  const { t } = useTranslation("debrief");

  useEffect(() => setTitle(t("debrief")));

  const dispatch = useDispatch();
  const history = useHistory();

  const impersonatedSeller = useSelector(currentImpersonatedSeller);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const currentAuction = useSelector(selectSelectedAuction);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    dispatch(auctionSelected(+queryParams.get("auctionId")));
  }, [history.location.search, dispatch]);

  useEffect(() => {
    dispatch(fetchAuctions());
  }, [dispatch, impersonatedSeller]);

  useEffect(() => {
    if (!currentAuction) return;

    dispatch(fetchLots({ auctionId: currentAuction.id }));
  }, [dispatch, currentAuction]);

  return (
    <>
      {currentAuction ? (
        <Page>
          <TitleWrapper>
            <h1>
              {`${t("debrief")} - ${currentAuction.id} ${
                currentAuction.name_translations[currentLanguage]
              }`}
            </h1>
          </TitleWrapper>

          <TwoTables>
            <LotCommentsStatsTable />
            <BuyerLocationsStatsTable />
          </TwoTables>

          <DebriefLotsTablesContainer />
          <DebriefForm />
        </Page>
      ) : (
        <Loader big active />
      )}
    </>
  );
};

export default Debrief;
