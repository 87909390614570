import {
  deleteData as vavatoDeleteData,
  fetchData as vavatoFetchData,
  postData as vavatoPostData,
  putData as vavatoPutData,
} from "vavato-ui";
import axios from "axios";

const deleteData = (resources, headers = {}) => {
  return vavatoDeleteData(resources, { ...headers, ...handleJwt() });
};

const fetchData = (resources, headers = {}) => {
  return vavatoFetchData(resources, { ...headers, ...handleJwt() });
};

const postData = (resources, params = {}, headers = {}) => {
  return vavatoPostData(resources, params, { ...headers, ...handleJwt() });
};

const putData = (resources, params = {}, headers = {}) => {
  return vavatoPutData(resources, params, { ...headers, ...handleJwt() });
};

const handleJwt = () => {
  const jwt = localStorage.getItem("jwt");

  if (jwt) {
    return updateBearerToken(jwt);
  }

  return updateBearerToken(localStorage.getItem("accessToken"));
};

const updateBearerToken = (token) => {
  return { Authorization: `Bearer ${token}` };
};

const getOrSetJwt = () => {
  const jwt = localStorage.getItem("jwt");
  const params = new URL(document.location).searchParams;
  const jwtFromParams = params.get("jwt");
  const refreshToken = params.get("refreshToken");
  const jwtExpiration = params.get("jwtExpiration");

  if (jwt !== "null" && jwtFromParams !== null && jwt !== jwtFromParams) {
    localStorage.setItem("jwt", jwtFromParams);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("jwtExpiration", jwtExpiration);
  }
};

const checkJwtExpiration = (environment) => {
  const expiresAt = new Date(localStorage.getItem("jwtExpiration")).getTime();
  const tokenExpired = expiresAt < Date.now();

  if (tokenExpired) {
    refreshJwt(environment);
  }
};

const refreshJwt = (environment) => {
  const refreshToken = localStorage.getItem("refreshToken");

  const params = {
    refresh_token: refreshToken,
    grant_type: "refresh_token",
    scope: "openid offline_access",
    client_id: process.env.REACT_APP_TBA_CLIENT_ID,
  };

  return axios
    .post(process.env.REACT_APP_TBA_REFRESH_TOKEN_URL, {}, { params: params })
    .then((response) => {
      const currentDateTime = Date.now();
      const expiresAt =
        currentDateTime + response.data.id_token_expires_in * 1000;
      const expirationDateTime = new Date(expiresAt);

      localStorage.setItem("jwt", response.data.id_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      localStorage.setItem("jwtExpiration", expirationDateTime.toString());

      return response;
    })
    .catch((error) => {
      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("refreshToken");
      window.localStorage.removeItem("jwtExpiration");
      window.setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_VAVATO_WEBSITE}/en/homepage/login`;
      }, 2000);
    });
};

export {
  deleteData,
  fetchData,
  postData,
  putData,
  getOrSetJwt,
  handleJwt,
  checkJwtExpiration,
};
