export const getLocalHeaders = (contentType = "application/json") => {
  const jwt = window.localStorage.getItem("jwt");

  if (jwt) {
    return {
      "Api-Version": "v1",
      Authorization: `Bearer ${jwt}`,
      "Content-Type": contentType,
      "Token-Type": "Bearer",
    };
  } else {
    const token = window.localStorage.getItem("accessToken");
    const client = window.localStorage.getItem("client");
    const uid = window.localStorage.getItem("uid");

    return {
      "Api-Version": "v1",
      "Access-Token": token,
      Authorization: `Bearer ${token}`,
      Client: client,
      "Content-Type": contentType,
      "Token-Type": "Bearer",
      Uid: uid,
    };
  }
};

export const extraReqHeaders = (impersonatedSeller) => {
  const headers = {};

  if (impersonatedSeller)
    headers["X-Impersonate-Seller"] = impersonatedSeller.id;

  return headers;
};

export const handleErrors = (result, message, dispatch, error) => {
  if (result.status === 401) {
    dispatch(error("Please log in again"));
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("jwtExpiration");
    window.setTimeout(() => {
      window.location.href = `${process.env["REACT_APP_VAVATO_WEBSITE"]}/en/homepage/login`;
    }, 2000);
    throw new Error("Please log in again");
  }

  if (!result.ok) {
    dispatch(error(message));
    throw new Error(message);
  }
};
