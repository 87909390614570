import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Loader } from "vavato-ui";

import {
  loadingAuction,
  selectGroupedLotsForDebrief,
} from "../store/auctionsSlice";

import LotsWithCommentsTable from "./LotsWithCommentsTable";

const DebriefLotsTablesContainer = () => {
  const { t } = useTranslation("debrief");

  const loading = useSelector(loadingAuction);
  const groupedLots = useSelector(selectGroupedLotsForDebrief);

  if (loading) return <Loader big active />;

  return (
    <>
      <LotsWithCommentsTable
        lots={groupedLots.withComments}
        title={t("lotsTableTitles.withComments", {
          total: groupedLots.withComments.length,
        })}
      />
      <LotsWithCommentsTable
        lots={groupedLots.unpaidWithComments}
        title={t("lotsTableTitles.unpaidWithComments", {
          total: groupedLots.unpaidWithComments.length,
        })}
      />
      <LotsWithCommentsTable
        lots={groupedLots.unpaidWithoutComments}
        title={t("lotsTableTitles.unpaidWithoutComments", {
          total: groupedLots.unpaidWithoutComments.length,
        })}
      />
      <LotsWithCommentsTable
        lots={groupedLots.undelivered}
        title={t("lotsTableTitles.undelivered", {
          total: groupedLots.undelivered.length,
        })}
      />
      <LotsWithCommentsTable
        lots={groupedLots.withoutComments}
        title={t("lotsTableTitles.withoutComments", {
          total: groupedLots.withoutComments.length,
        })}
        startOpen={false}
      />
    </>
  );
};

export default DebriefLotsTablesContainer;
