import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { rgba } from "polished";
import { List, Label, formatDateOnly } from "vavato-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import LotItem from "./LotItem";

import { goodsHandoverForPickup } from "../store/goodsHandoverSlice";

const BuyerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.info};

  border-color: ${(props) => rgba(props.theme.secondary, 0.3)};
  border-style: solid;
  border-width: 1px 0px;
`;

const BuyerContact = styled.span`
  flex-basis: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;

  & > span {
    display: block;
    line-height: 1.25rem;
  }
`;

const CircleLabel = styled(Label)`
  border-radius: 25px;
  width: 16px;
  line-height: 16px;
  min-width: inherit;
  padding: 8px;
  background: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.lightText};
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: black;
  margin-right: 20px;
  padding: 5px;
`;

const Pickup = styled.div`
  padding-top: 10px;

  & > span {
    display: block;
    line-height: 1.25rem;
  }
`;

const LotsList = styled(List)`
  padding: 10px 15px 0px 15px;
  margin: 0;
  border-top: 1px solid ${(props) => rgba(props.theme.info, 0.2)};
  border-bottom: 1px solid ${(props) => rgba(props.theme.info, 0.2)};
  background-color: ${(props) => props.theme.lightBackground};

  & > li {
    align-items: center;
  }
`;

const PickupSummary = ({ buyerEntity, assignments, showLotLightbox }) => {
  const { t } = useTranslation("goodsHandover");

  const [showAssignments, setShowAssignments] = useState(true);

  const forPickup = useSelector(goodsHandoverForPickup);

  if (!buyerEntity || !assignments.length) return null;

  const deliveredBy =
    assignments.length && assignments[0].delivered_by
      ? t("lotDeliveredBy", { deliveredBy: assignments[0].delivered_by })
      : t("lotDelivered");

  const pickedUpBy =
    assignments.length && assignments[0].transporter_name
      ? `${assignments[0].transporter_company_name} (${assignments[0].transporter_name}), ${assignments[0].transporter_license_plate_number}`
      : t("lotPicker.buyer");

  return (
    <>
      <BuyerInfo>
        <BuyerContact>
          <span>{buyerEntity.company_name}</span>
          <span>{buyerEntity.user_name}</span>
          {showAssignments ? (
            <>
              <span>{buyerEntity.address}</span>
              <span>T: {buyerEntity.phone || t("notAvailable")}</span>
            </>
          ) : (
            ""
          )}
          {forPickup ? (
            ""
          ) : (
            <Pickup>
              <span>
                {`${t("pickup")}: ${formatDateOnly(
                  assignments[0].delivered_at
                )}`}
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("pickedUpBy", { pickedUpBy }),
                }}
              ></span>
              <span dangerouslySetInnerHTML={{ __html: deliveredBy }}></span>
            </Pickup>
          )}
        </BuyerContact>
        {forPickup ? (
          <>
            <CircleLabel>{assignments.length}</CircleLabel>
            <Icon
              icon={showAssignments ? faChevronUp : faChevronDown}
              onClick={() => setShowAssignments(!showAssignments)}
            />
          </>
        ) : (
          ""
        )}
      </BuyerInfo>
      {showAssignments ? (
        <LotsList>
          {assignments.map((assignment) => (
            <LotItem
              summary
              key={assignment.id}
              assignment={assignment}
              showLotLightbox={showLotLightbox}
            />
          ))}
        </LotsList>
      ) : (
        ""
      )}
    </>
  );
};

export default PickupSummary;
