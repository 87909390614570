import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormField } from "vavato-ui";

import { setCurrentDeliveredBy } from "../store/goodsHandoverSlice";

const Container = styled.div`
  padding: 0px 10px;

  & > div {
    margin: 16px 0 0 0 !important;
  }
`;

const DeliveredByInput = () => {
  const { t } = useTranslation("goodsHandover");
  const dispatch = useDispatch();

  const currentDeliveredBy = useSelector(
    (state) => state.goodsHandover.currentDeliveredBy
  );

  return (
    <Container>
      <FormField name="delivered_by" label={t("deliveredByLabel")}>
        <input
          name="delivered_by"
          type="text"
          placeholder={t("deliveredByPlaceholder")}
          value={currentDeliveredBy}
          onChange={(e) => dispatch(setCurrentDeliveredBy(e.target.value))}
        />
      </FormField>
    </Container>
  );
};

export default DeliveredByInput;
