import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "vavato-ui";
import { assignmentStatusLabel } from "../utils/ui";
import styled from "styled-components";

import DebriefCommentContainer from "./DebriefCommentContainer";
import LotBidder from "./LotBidder";

import { selectCurrentLanguage } from "../store/profileSlice";

const ZeroHeightRow = styled.tr`
  height: 0 !important;
`;

const LotsWithCommentsTableRow = ({ lot }) => {
  const { t } = useTranslation("auctions");

  const [commentOpen, setCommentOpen] = useState(
    lot.assignment.comments || lot.assignment.issue_type
  );

  const currentLanguage = useSelector(selectCurrentLanguage);

  return (
    <>
      <tr>
        <td>{lot.lot_number}</td>
        <td onClick={() => setCommentOpen(!commentOpen)}>
          <FontAwesomeIcon icon={commentOpen ? faAngleUp : faAngleDown} />
        </td>
        <td>{lot.name_translations[currentLanguage]}</td>
        <td>{lot.categories[0].name_translations[currentLanguage]}</td>
        <td>
          <LotBidder
            bidderName={lot.assignment.buyer_entity.user_name}
            countryCode={lot.assignment.buyer_entity.country_code}
            countryName={
              lot.assignment.buyer_entity.country_name_translations[
                currentLanguage
              ]
            }
          />
        </td>
        <td>{lot.assignment.buyer_entity.phone}</td>
        <td className="number">{formatCurrency(lot.assignment.price)}</td>
        <td className="number">{lot.bids_count}</td>
        <td className="number">
          {assignmentStatusLabel(lot.assignment.status, t)}
        </td>
      </tr>
      {commentOpen ? (
        <tr>
          <td></td>
          <td></td>
          <td colSpan="4">
            <DebriefCommentContainer assignment={lot.assignment} />
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      ) : (
        <ZeroHeightRow />
      )}
    </>
  );
};

export default LotsWithCommentsTableRow;
