import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { rgba } from "polished";
import { Button, FormField } from "vavato-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import CommentIssues from "./CommentIssues";

import { saveAssignmentComment } from "../store/auctionsSlice";

const CommentSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px 0px;
  position: relative;

  &.centered {
    justify-content: center;
  }
`;

const Comment = styled.div`
  color: ${(props) => rgba(props.theme.text, 0.8)};
  padding-right: 10px;
  font-style: italic;
  text-transform: none;
`;

const IssueType = styled.span`
  font-weight: bold;
`;

const CommentLink = styled.span`
  color: ${(props) => rgba(props.theme.text, 0.8)};
  cursor: pointer;
  text-decoration-line: underline;
  line-height: 16px;
`;

const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 0;
  color: ${(props) => props.theme.text};
`;

const Form = styled.div`
  & > div,
  button {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DebriefCommentContainer = ({ assignment }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const [formOpen, setFormOpen] = useState(false);

  const issueTypeRef = useRef(null);
  const commentsRef = useRef(null);

  const saveComment = () => {
    dispatch(
      saveAssignmentComment({
        id: assignment.id,
        issueType: issueTypeRef.current.value,
        comments: commentsRef.current.value,
      })
    );
    setFormOpen(false);
  };

  return (
    <>
      {formOpen ? (
        <Form>
          <FormField name="issue" label={t("comment.issueLabel")}>
            <select
              name="issue"
              id="issue"
              ref={issueTypeRef}
              defaultValue={assignment.issue_type}
            >
              <CommentIssues />
            </select>
          </FormField>
          <FormField name="comment" label={t("comment.commentLabel")}>
            <textarea
              name="comment"
              placeholder={t("comment.placeholder")}
              ref={commentsRef}
              defaultValue={assignment.comments}
            />
          </FormField>
          <ButtonGroup>
            <Button className="outline" onClick={() => setFormOpen(false)}>
              {t("cancel")}
            </Button>
            <Button onClick={() => saveComment()}>{t("save")}</Button>
          </ButtonGroup>
        </Form>
      ) : (
        <>
          {assignment.issue_type || assignment.comments ? (
            <CommentSection>
              <EditIcon>
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  onClick={() => setFormOpen(true)}
                />
              </EditIcon>
              <Comment>
                {assignment.issue_type?.length ? (
                  <IssueType>{`${assignment.issue_type}. `}</IssueType>
                ) : (
                  ""
                )}
                {assignment.comments?.length ? (
                  <span>{assignment.comments}</span>
                ) : (
                  ""
                )}
              </Comment>
            </CommentSection>
          ) : (
            <CommentSection className="centered">
              <CommentLink onClick={() => setFormOpen(!formOpen)}>
                Add comment
              </CommentLink>
            </CommentSection>
          )}
        </>
      )}
    </>
  );
};

export default DebriefCommentContainer;
