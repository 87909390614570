import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import GoodsHandoverTitle from "./GoodsHandoverTitle";
import GoodsHandoverHeaderMobile from "./GoodsHandoverHeaderMobile";
import DropdownFilter from "./DropdownFilter";
import GoodsHandoverSyncOffline from "./GoodsHandoverSyncOffline";
import QueryStringSearch from "./QueryStringSearch";
import StartDebriefButton from "./StartDebriefButton";
import { GOODS_HANDOVER_INTERVALS as INTERVALS } from "../constants";

import {
  selectAllGoodsHandover,
  goodsHandoverLoading,
  goodsHandoverForPickup,
} from "../store/goodsHandoverSlice";

import {
  selectAllGoodsHandoverAuctions,
  goodsHandoverAdminLoading,
} from "../store/goodsHandoverAdminSlice";

import { currentImpersonatedSeller } from "../store/impersonateSlice";

import {
  selectCurrentLanguage,
  impersonateAbility,
  selectLoadingProfile,
} from "../store/profileSlice";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 100px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Filters = styled.div`
  display: flex;
  column-gap: 20px;
`;

const DropdownFilterWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const ClickableFilter = styled.span`
  flex: 1;
`;

const GoodsHandoverHeader = ({
  selectedAuctionId,
  selectedTimeInterval,
  selectedSearchQuery,
}) => {
  const { t } = useTranslation("goodsHandover");

  const history = useHistory();

  const currentLanguage = useSelector(selectCurrentLanguage);

  const forPickup = useSelector(goodsHandoverForPickup);

  const goodsHandovers = useSelector(selectAllGoodsHandover);
  const allAuctions = useSelector(selectAllGoodsHandoverAuctions);

  const canImpersonate = useSelector(impersonateAbility);
  const impersonatedSeller = useSelector(currentImpersonatedSeller);

  const loadingProfile = useSelector(selectLoadingProfile);
  const dataLoading = useSelector(goodsHandoverLoading);
  const dataAdminLoading = useSelector(goodsHandoverAdminLoading);

  const showAllAuctions = canImpersonate && !impersonatedSeller;

  const auctions = showAllAuctions
    ? allAuctions
    : goodsHandovers
        .flatMap((goodsHandover) => goodsHandover.assignments)
        .map((assignment) => assignment.auction)
        .sort((a, b) => b.id - a.id);

  const setAuctionFilter = useCallback(
    (id) => {
      const queryParams = new URLSearchParams(history.location.search);

      if (id) {
        // When changing the AuctionId, there is no point in keeping the buyerId
        // since the buyerSearch will allow to search for current buyers.
        queryParams.delete("buyerId");
        queryParams.delete("searchQuery");
        queryParams.set("auctionId", id);
      } else {
        queryParams.delete("auctionId");
      }

      history.push(
        [history.location.pathname, queryParams.toString()]
          .filter((el) => el.length)
          .join("?")
      );
    },
    [history]
  );

  const setTimeInterval = (value) => {
    setAuctionFilter(null);

    const queryParams = new URLSearchParams(history.location.search);

    queryParams.delete("buyerId");

    if (value) {
      queryParams.set("releaseDate", value);
    }

    history.push(
      [history.location.pathname, queryParams.toString()]
        .filter((el) => el.length)
        .join("?")
    );
  };

  const auctionsList = auctions.filter(function ({ id }) {
    return !this.has(id) && this.add(id);
  }, new Set());

  const defaultLabel = showAllAuctions ? t("none") : t("allAuctions");

  const currentAuction = auctionsList.find(
    (auction) => auction.id === selectedAuctionId
  );

  let selectedAuctionLabel;
  if (currentAuction) {
    selectedAuctionLabel = `${currentAuction.id} - ${currentAuction.name_translations[currentLanguage]}`;
  } else {
    selectedAuctionLabel = defaultLabel;
  }

  useEffect(() => {
    if (dataAdminLoading || dataLoading || loadingProfile) return;
    if (selectedAuctionId && !currentAuction) setAuctionFilter(null);
  }, [
    dataAdminLoading,
    dataLoading,
    loadingProfile,
    selectedAuctionId,
    currentAuction,
    setAuctionFilter,
  ]);

  return (
    <>
      <Header>
        {forPickup ? <GoodsHandoverSyncOffline /> : <GoodsHandoverTitle />}
        <Filters>
          <DropdownFilterWrapper>
            <h6>&nbsp;</h6>
            <StartDebriefButton selectedAuctionId={selectedAuctionId} />
          </DropdownFilterWrapper>
          <DropdownFilterWrapper>
            <h6>&nbsp;</h6>
            <QueryStringSearch selectedSearchQuery={selectedSearchQuery} />
          </DropdownFilterWrapper>
          <DropdownFilterWrapper>
            <h6>{t("auction")}</h6>
            <DropdownFilter selected={selectedAuctionLabel}>
              {[
                <ClickableFilter
                  key="default_value"
                  onClick={() => setAuctionFilter(null)}
                >
                  {defaultLabel}
                </ClickableFilter>,
              ].concat(
                auctionsList.map((auction) => (
                  <ClickableFilter
                    key={auction.id}
                    onClick={() => setAuctionFilter(auction.id)}
                  >
                    {auction.id} - {auction.name_translations[currentLanguage]}
                  </ClickableFilter>
                ))
              )}
            </DropdownFilter>
          </DropdownFilterWrapper>
          <DropdownFilterWrapper>
            <h6>{t("releaseDate.title")}</h6>
            <DropdownFilter
              selected={t(
                `releaseDate.${selectedTimeInterval}`,
                t("releaseDate.last_60_days")
              )}
            >
              {INTERVALS.map((interval) => (
                <ClickableFilter
                  key={interval}
                  onClick={() => setTimeInterval(interval)}
                >
                  {t(`releaseDate.${interval}`)}
                </ClickableFilter>
              ))}
            </DropdownFilter>
          </DropdownFilterWrapper>
        </Filters>
      </Header>
      <GoodsHandoverHeaderMobile
        selectedAuctionId={selectedAuctionId}
        selectedSearchQuery={selectedSearchQuery}
        selectedTimeInterval={selectedTimeInterval}
        selectedAuctionLabel={selectedAuctionLabel}
        setTimeInterval={setTimeInterval}
        setAuctionFilter={setAuctionFilter}
        auctionsList={auctionsList}
      />
    </>
  );
};

export default GoodsHandoverHeader;
