import React from "react";
import styled from "styled-components";

const StepperContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 66%;
  margin: 64px auto;
  text-align: center;
`;

const StepContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const StepBall = styled.div`
  display: flex;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 2px solid ${(props) => props.theme.primary};
  margin-bottom: 16px;
`;

const ActiveStepBall = styled(StepBall)`
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.lightText};
`;

const InactiveStepBall = styled(StepBall)`
  background: ${(props) => props.theme.lightText};
  color: ${(props) => props.theme.primary};
`;

const StepText = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3em;

  small {
    display: block;
    font-weight: 200;
    min-height: 55px;
  }
`;

const Separator = styled.div`
  width: 20%;
  border-bottom: 2px solid ${(props) => props.theme.primary};
  height: 20px;

  :last-child {
    display: none;
  }
`;

const Step = ({ content, children, active }) => {
  return (
    <StepContainer>
      {active ? (
        <ActiveStepBall>{content}</ActiveStepBall>
      ) : (
        <InactiveStepBall>{content}</InactiveStepBall>
      )}
      <StepText>{children}</StepText>
    </StepContainer>
  );
};

export const Stepper = ({ steps, currentStep }) => {
  if (!steps.length) {
    return null;
  }

  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <React.Fragment key={step.content}>
          <Step active={index <= currentStep} content={step.content}>
            {step.title}
            <small>{step.subtitle}</small>
          </Step>
          <Separator />
        </React.Fragment>
      ))}
    </StepperContainer>
  );
};

export default Stepper;
