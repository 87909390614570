import styled from "styled-components";

const ScrollableList = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 130px - 110px);

  &.details {
    max-height: calc(100vh - 130px - 90px);
  }

  &.comment {
    max-height: calc(100vh - 40px - 100px - 100px - 20px);
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    margin-left: -10px;
    margin-right: -10px;
    padding: 0 10px;

    &.buyers {
      max-height: calc(100vh - 40px - 5px - 72px - 37px - 20px);
    }

    &.lots {
      max-height: calc(100vh - 40px - 5px - 155px - 7px - 20px);

      &.with-footer {
        max-height: calc(100vh - 40px - 5px - 155px - 7px - 70px - 20px);
      }
    }

    &.details {
      padding: 0;
      max-height: calc(100vh - 40px - 5px - 94px - 18px - 70px - 20px);
    }

    &.comment {
      max-height: calc(100vh - 40px - 94px - 10px);
    }
  }
`;

export default ScrollableList;
