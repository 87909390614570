// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// This is public knowledge, so no need to abstract away from git.
const firebaseConfig = {
  apiKey: "AIzaSyARJf-vbUbRcFl38V2nZLRJcaIqgK87_yw",
  authDomain: "vavato-seller-ui.firebaseapp.com",
  projectId: "vavato-seller-ui",
  storageBucket: "vavato-seller-ui.appspot.com",
  messagingSenderId: "219807247076",
  appId: "1:219807247076:web:5e0516914cddca3e315af1",
  measurementId: "G-S5MLV6TQT8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
