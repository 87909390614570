import { configureStore, createReducer } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";

import impersonateReducer from "./store/impersonateSlice";
import goodsHandoverReducer from "./store/goodsHandoverSlice";
import goodsHandoverAdminReducer from "./store/goodsHandoverAdminSlice";
import auctionsReducer from "./store/auctionsSlice";
import toasterReducer from "./store/toasterSlice";
import profileReducer from "./store/profileSlice";

import { DEFAULT_LANGUAGE } from "./constants";

// Set here temporarily since we are moving to use only window.localStorage for
// impersonatedSeller but we were previously setting impersonatedSeller on the
// Cookie and that would get moved to window.localStorage without being
// stringified.
// Can be removed a couple of days after deployment.
new Cookies().remove("impersonatedSeller");

const preloadedState = {
  impersonate: {
    impersonatedSeller: window.localStorage.getItem("impersonatedSeller")
      ? JSON.parse(window.localStorage.getItem("impersonatedSeller"))
      : null,
  },
  profile: {
    language: window.localStorage.getItem("language") || DEFAULT_LANGUAGE,
    features: {},
    email: null,
    name: null,
    canImpersonate: null,
    loading: true,
  },
};

const store = configureStore({
  reducer: {
    impersonate: impersonateReducer,
    goodsHandover: goodsHandoverReducer,
    goodsHandoverAdmin: goodsHandoverAdminReducer,
    auctions: auctionsReducer,
    toasters: toasterReducer,
    profile: profileReducer,
    environment: createReducer(process.env, () => {}),
  },
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
});

export default store;
