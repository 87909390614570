import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { rgba } from "polished";
import { Button, ButtonGroup, FormField, VavatoTheme } from "vavato-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronDoubleUp } from "@fortawesome/pro-solid-svg-icons";

import QueryStringSearch from "./QueryStringSearch";
import { GOODS_HANDOVER_INTERVALS as INTERVALS } from "../constants";

import { selectCurrentLanguage } from "../store/profileSlice";

import {
  selectSelectedBuyerId,
  selectBuyer,
  selectGoodsHandoverById,
  setCurrentColumn,
  setCommentAssignment,
} from "../store/goodsHandoverSlice";

const GoodsHandoverHeaderMobileContainer = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: inherit;
    padding: 0 10px;
  }
`;

const Header = styled.header`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 5px 0px 10px;
`;

const FilterHeaders = styled.div`
  display: flex;
  align-items: center;
  .shrinked > input {
    padding-top: 3px;
  }
`;

const Filters = styled.div`
  transition: all 0.4s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0);
  max-height: 0;

  &.visible {
    max-height: 500px;
    transform: scaleY(1);
  }
`;

const CurrentIntervalButton = styled(Button)`
  margin-right: 0px;

  &.active {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.lightText};
  }

  @media only screen and (max-width: 768px) {
    font-size: 10px;
    line-height: 1.8;
  }
`;

const StyledSelect = styled.select`
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.text};
  margin-bottom: 10px;
`;

const CloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-transform: uppercase;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: auto;
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackIcon = styled.span`
  color: ${(props) => props.theme.text};
  font-size: 12px;
`;

const BackTitle = styled.h6`
  margin: 5px;
  color: ${(props) => props.theme.text};
  font-size: 12px;
  font-weight: 400 important!;
`;

const AuctionTitle = styled.h3`
  margin-block-start: 0px !important;
  margin-block-end: 10px !important;
`;

const BuyerContainer = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.info};
    padding: 10px;
    margin-left: -10px;

    border-color: ${(props) => rgba(props.theme.secondary, 0.3)};
    border-style: solid;
    border-width: 1px 0px;
  }
`;

const BuyerContact = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  & > span {
    display: block;
    line-height: 1.25rem;
  }
`;

const SearchContainer = styled.div`
  input {
    line-height: 1em;
  }
`;

const CloseLabel = styled.span`
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.tableHeaders};
  margin: 8px 0px 2px 0px;
`;

const GoodsHandoverHeaderMobile = ({
  selectedAuctionId,
  selectedSearchQuery,
  selectedTimeInterval,
  selectedAuctionLabel,
  setTimeInterval,
  setAuctionFilter,
  auctionsList,
}) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const [showFilters, setShowFilters] = useState(false);

  const selectedBuyerId = useSelector(selectSelectedBuyerId);

  const selectedGoodsHandover = useSelector((state) =>
    selectGoodsHandoverById(state, selectedBuyerId)
  );
  const currentColumn = useSelector(
    (state) => state.goodsHandover.currentColumn
  );
  const commentAssignment = useSelector(
    (state) => state.goodsHandover.commentAssignment
  );

  const currentLanguage = useSelector(selectCurrentLanguage);

  const handleBackButtonClick = () => {
    if (commentAssignment) {
      dispatch(setCommentAssignment(null));
    } else if (currentColumn === "details") {
      dispatch(setCurrentColumn("lots"));
    } else if (currentColumn === "lots") {
      dispatch(selectBuyer(null));
    }
  };

  return (
    <GoodsHandoverHeaderMobileContainer>
      <Header>
        {selectedBuyerId ? (
          <BackContainer onClick={handleBackButtonClick}>
            <BackIcon>
              <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
            </BackIcon>
            <BackTitle>Back</BackTitle>
          </BackContainer>
        ) : (
          <div />
        )}
        <FilterHeaders>
          <SearchContainer>
            <QueryStringSearch selectedSearchQuery={selectedSearchQuery} />
          </SearchContainer>
          <CurrentIntervalButton
            className={showFilters ? "active" : ""}
            onClick={() => setShowFilters(!showFilters)}
          >
            {t(
              `releaseDate.${selectedTimeInterval}`,
              t("releaseDate.last_60_days")
            )}
          </CurrentIntervalButton>
        </FilterHeaders>
      </Header>
      <Filters className={showFilters ? "visible" : ""}>
        <FormField inline label={t("intervalLabel")}>
          <ButtonGroup
            onClick={setTimeInterval}
            values={INTERVALS.map((interval) => {
              return {
                value: interval,
                text: t(`releaseDate.${interval}`),
                selected: selectedTimeInterval === interval,
              };
            })}
            selectedStyle={{
              color: VavatoTheme.lightText,
              backgroundColor: VavatoTheme.secondary,
            }}
          />
        </FormField>
        <FormField inline label={t("auctionLabel")}>
          <StyledSelect
            onChange={(e) => setAuctionFilter(e.target.value)}
            value={selectedAuctionId || ""}
          >
            {[
              <option key="" value="">
                {t("auctionPrompt")}
              </option>,
            ].concat(
              auctionsList.map((auction) => (
                <option key={auction.id} value={auction.id}>
                  {auction.id} - {auction.name_translations[currentLanguage]}
                </option>
              ))
            )}
          </StyledSelect>
        </FormField>
        <Footer>
          <CloseContainer onClick={() => setShowFilters(false)}>
            <Icon icon={faChevronDoubleUp}></Icon>
            <CloseLabel>{t("close")}</CloseLabel>
          </CloseContainer>
        </Footer>
      </Filters>
      {selectedGoodsHandover ? (
        <>
          <AuctionTitle>{selectedAuctionLabel}</AuctionTitle>
          {currentColumn === "lots" ? (
            <BuyerContainer>
              <BuyerContact>
                <span>
                  {selectedGoodsHandover.buyer_entity.company_name
                    ? `${selectedGoodsHandover.buyer_entity.company_name} (${selectedGoodsHandover.buyer_entity.user_name})`
                    : selectedGoodsHandover.buyer_entity.user_name}
                </span>
              </BuyerContact>
            </BuyerContainer>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </GoodsHandoverHeaderMobileContainer>
  );
};

export default GoodsHandoverHeaderMobile;
