import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table, formatCurrency } from "vavato-ui";

import { totalValue } from "../utils/ui";

import { loadingAuction, selectLots } from "../store/auctionsSlice";

const AuctionStatsTable = () => {
  const { t } = useTranslation("auctions");
  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  if (loading) return null;

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("metric")}</th>
          <th className="number">{t("value")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("totalLots")}</td>
          <td className="number">{lots.length}</td>
        </tr>
        <tr>
          <td>{t("totalExpectedValue")}</td>
          <td className="number">
            {formatCurrency(totalValue(lots, "expected_sale_price"))}
          </td>
        </tr>
        <tr>
          <td>{t("totalOpeningBids")}</td>
          <td className="number">
            {formatCurrency(totalValue(lots, "opening_bid_price"))}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AuctionStatsTable;
