import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ButtonGroup, FormField, VavatoTheme } from "vavato-ui";
import styled from "styled-components";

import { PICKUP_ENTITIES } from "../constants";

import {
  setTransporterMode,
  setTransporterCompany,
  setTransporterLicensePlate,
  setTransporterName,
} from "../store/goodsHandoverSlice";

const Selector = styled.div`
  margin: 21px 0 5px 0;
  padding: 0px 10px;

  & div div {
    width: 100%;
    background-color: #ffffff;
    padding-left: 0;
    padding-right: 0;
  }

  & button {
    flex: 1 1;
  }
`;

const SelectorLabel = styled.h5`
  color: ${(props) => props.theme.tableHeaders} !important;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 4px;
`;

const TransporterFields = styled.div`
  & > div {
    margin: 21px 0 21px 0 !important;
    padding: 0px 10px;
  }
`;

const TransporterForm = () => {
  const { t } = useTranslation("goodsHandover");
  const dispatch = useDispatch();

  const transporterMode = useSelector(
    (state) => state.goodsHandover.transporterMode
  );
  const transporterCompany = useSelector(
    (state) => state.goodsHandover.transporterCompany
  );
  const transporterLicensePlate = useSelector(
    (state) => state.goodsHandover.transporterLicensePlate
  );
  const transporterName = useSelector(
    (state) => state.goodsHandover.transporterName
  );

  return (
    <>
      <Selector>
        <SelectorLabel>{t("pickupByLabel")}</SelectorLabel>
        <ButtonGroup
          onClick={(e) => dispatch(setTransporterMode(e === "transporter"))}
          values={PICKUP_ENTITIES.map((lotPicker) => {
            return {
              value: lotPicker,
              text: t(`lotPicker.${lotPicker}`),
              selected: transporterMode
                ? lotPicker === "transporter"
                : lotPicker !== "transporter",
            };
          })}
          selectedStyle={{
            color: VavatoTheme.lightText,
            backgroundColor: VavatoTheme.secondary,
          }}
        />
      </Selector>
      {transporterMode ? (
        <TransporterFields>
          <FormField label={t("transporterCompany")}>
            <input
              type="text"
              placeholder={t("transporterCompanyPlaceholder")}
              value={transporterCompany}
              onChange={(e) => dispatch(setTransporterCompany(e.target.value))}
            />
          </FormField>
          <FormField label={t("transporterLicensePlate")}>
            <input
              type="text"
              placeholder={t("transporterLicensePlatePlaceholder")}
              value={transporterLicensePlate}
              onChange={(e) =>
                dispatch(setTransporterLicensePlate(e.target.value))
              }
            />
          </FormField>
          <FormField label={t("transporterName")}>
            <input
              type="text"
              placeholder={t("transporterNamePlaceholder")}
              value={transporterName}
              onChange={(e) => dispatch(setTransporterName(e.target.value))}
            />
          </FormField>
        </TransporterFields>
      ) : (
        ""
      )}
    </>
  );
};

export default TransporterForm;
