import { useTranslation } from "react-i18next";

import { COMMENT_ISSUES } from "../constants";

const CommentIssues = () => {
  const { t } = useTranslation("goodsHandover");

  let options = [
    <option key="" value="">
      {t("comment.prompt")}
    </option>,
  ];

  COMMENT_ISSUES.forEach((issue) => {
    const value = t(`comment.issues.${issue}`);

    options.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  });

  return options;
};

export default CommentIssues;
