import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePubNub } from "pubnub-react";
import styled from "styled-components";
import { Loader } from "vavato-ui";
import { useLocation } from "react-router-dom";

import BuyersList from "../components/BuyersList.js";
import GoodsHandoverHeader from "../components/GoodsHandoverHeader.js";
import LotsList from "../components/LotsList";
import LotLightbox from "../components/LotLightbox";
import PickupColumn from "../components/PickupColumn";
import CommentColumn from "../components/CommentColumn";
import DeliveredColumn from "../components/DeliveredColumn";

import { MOBILE_NAVIGATION_WIDTH } from "../constants";

import { currentImpersonatedSeller } from "../store/impersonateSlice";

import {
  impersonateAbility,
  selectLoadingProfile,
} from "../store/profileSlice";

import {
  clear,
  fetchAllGoodsHandover,
  invalidateGoodsHandovers,
  showForPickup,
  markAssignmentAsPaid,
  removeDeliveredAssignment,
} from "../store/goodsHandoverSlice";

import {
  fetchAuctions,
  invalidateGoodsHandoversAdmin,
  adminLoaded,
} from "../store/goodsHandoverAdminSlice";

import { lotsDelivered } from "../store/auctionsSlice";

const GoodsHandoverContainer = styled.div`
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;

  @media only screen and (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const GoodsHandoverBody = styled.div`
  display: flex;
  height: calc(100vh - 130px - 10px);
  input {
    background: white;
  }

  @media only screen and (max-width: 768px) {
    overflow: hidden;
    padding-bottom: 10px;

    &.buyers {
      height: calc(100vh - 40px - 5px - 72px - 10px);
    }

    &.lots {
      height: calc(100vh - 40px - 5px - 155px - 10px);
    }

    &.details {
      height: calc(100vh - 40px - 5px - 94px - 20px);
    }
  }
`;

const Column = styled.div`
  padding: 0 10px;

  @media only screen and (max-width: 768px) {
    flex-basis: auto;
    min-width: calc(100vw - 20px);

    transition: transform 1s;
  }
`;

const BuyersColumn = styled(Column)`
  flex-basis: 25%;
  background: ${(props) => props.theme.lightBackground};
  border-top: 2px solid ${(props) => props.theme.lightText};
  @media only screen and (max-width: 768px) {
    padding-top: 16px;
  }
`;

const LotsColumn = styled(Column)`
  flex-basis: 40%;
  background: ${(props) => props.theme.lightBackground};
  border-top: 2px solid ${(props) => props.theme.lightText};
`;

const DetailsColumn = styled(Column)`
  flex-basis: 35%;
  border-top: 2px solid ${(props) => props.theme.lightText};
`;

const GoodsHandover = ({ forPickup, setTitle }) => {
  const { t } = useTranslation("goodsHandover");

  useEffect(() => setTitle(t("goodsHandover")));

  useEffect(() => dispatch(showForPickup(forPickup)));

  const pubnub = usePubNub();
  const location = useLocation();
  const dispatch = useDispatch();

  const canImpersonate = useSelector(impersonateAbility);
  const sellerEmail = useSelector((state) => state.profile.email);

  let assignmentChannel;
  if (canImpersonate) {
    assignmentChannel = `assignments`;
  } else if (sellerEmail) {
    assignmentChannel = `assignments-${sellerEmail}`;
  }

  const handleMessage = useCallback(
    (event) => {
      const message = event.message;

      if (message.status === "delivered") {
        dispatch(lotsDelivered(1));
        dispatch(
          removeDeliveredAssignment({
            id: message.id,
            buyerId: message.buyer_id,
          })
        );
      } else if (message.status === "paid") {
        dispatch(
          markAssignmentAsPaid({
            id: message.id,
            buyerId: message.buyer_id,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!assignmentChannel) return;

    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels: [assignmentChannel] });
  }, [pubnub, assignmentChannel, handleMessage]);

  const loadingProfile = useSelector(selectLoadingProfile);
  const impersonatedSeller = useSelector(currentImpersonatedSeller);

  const goodsHandoversLoaded = useSelector(
    (state) => state.goodsHandover.dataLoaded
  );
  const goodsHandoversAdminLoaded = useSelector(
    (state) => state.goodsHandoverAdmin.dataLoaded
  );
  const goodsHandoversLoading = useSelector(
    (state) => state.goodsHandover.loading
  );
  const goodsHandoversAdminLoading = useSelector(
    (state) => state.goodsHandoverAdmin.loading
  );
  const commentAssignment = useSelector(
    (state) => state.goodsHandover.commentAssignment
  );
  const currentColumn = useSelector(
    (state) => state.goodsHandover.currentColumn
  );

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxLot, setLightboxLot] = useState(null);

  const [selectedTimeInterval, setSelectedTimeInterval] = useState(null);
  const [searchedBuyerId, setSearchedBuyerId] = useState(null);
  const [selectedAuctionId, setSelectedAuctionId] = useState(null);
  const [selectedSearchQuery, setSelectedSearchQuery] = useState(null);

  const buyersColumnRef = useRef();
  const lotsColumnRef = useRef();
  const detailsColumnRef = useRef();
  const goodsHandoverBodyRef = useRef();

  const shiftColumns = useCallback(() => {
    if (window.window.innerWidth > MOBILE_NAVIGATION_WIDTH) return;

    if (
      !buyersColumnRef.current ||
      !lotsColumnRef.current ||
      !detailsColumnRef.current
    )
      return;

    let value;
    if (currentColumn === "buyers") {
      value = "0";
    } else if (currentColumn === "lots") {
      value = "-100";
    } else {
      value = "-200";
    }
    buyersColumnRef.current.style.transform = `translateX(${value}%)`;
    lotsColumnRef.current.style.transform = `translateX(${value}%)`;
    detailsColumnRef.current.style.transform = `translateX(${value}%)`;

    goodsHandoverBodyRef.current.classList.add(currentColumn);
  }, [currentColumn]);

  useEffect(() => {
    shiftColumns();
  }, [shiftColumns]);

  const clearGoodsHandover =
    canImpersonate &&
    !impersonatedSeller &&
    !selectedSearchQuery &&
    !selectedAuctionId &&
    !searchedBuyerId;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    setSelectedSearchQuery(queryParams.get("searchQuery") || null);
    setSelectedAuctionId(+queryParams.get("auctionId") || null);
    setSearchedBuyerId(+queryParams.get("buyerId") || null);
    setSelectedTimeInterval(queryParams.get("releaseDate") || "last_60_days");
  }, [location.search]);

  useEffect(() => {
    dispatch(invalidateGoodsHandovers());
    dispatch(invalidateGoodsHandoversAdmin());
  }, [dispatch, selectedTimeInterval, impersonatedSeller]);

  useEffect(() => {
    if (loadingProfile) return;
    if ((!canImpersonate || impersonatedSeller) && goodsHandoversLoaded) return;

    if (clearGoodsHandover) {
      dispatch(clear());
    } else {
      dispatch(
        fetchAllGoodsHandover({
          selectedTimeInterval,
          searchedBuyerId,
          selectedAuctionId,
          selectedSearchQuery,
        })
      );
    }
  }, [
    dispatch,
    loadingProfile,
    selectedTimeInterval,
    canImpersonate,
    impersonatedSeller,
    selectedSearchQuery,
    selectedAuctionId,
    searchedBuyerId,
    goodsHandoversLoaded,
    clearGoodsHandover,
  ]);

  useEffect(() => {
    if (loadingProfile) return;
    if (canImpersonate) {
      dispatch(fetchAuctions(selectedTimeInterval));
    } else {
      dispatch(adminLoaded());
    }
  }, [dispatch, loadingProfile, selectedTimeInterval, canImpersonate]);

  const showLotLightbox = (lot) => {
    if (lot.image_urls.length > 0) {
      setLightboxOpen(true);
      setLightboxLot(lot);
    }
  };

  return (
    <GoodsHandoverContainer>
      <GoodsHandoverHeader
        selectedAuctionId={selectedAuctionId}
        selectedTimeInterval={selectedTimeInterval}
        selectedSearchQuery={selectedSearchQuery}
      />
      <GoodsHandoverBody ref={goodsHandoverBodyRef} className={currentColumn}>
        {loadingProfile ||
        (!goodsHandoversLoaded && !goodsHandoversAdminLoaded) ? (
          <Loader active big />
        ) : (
          <>
            <BuyersColumn ref={buyersColumnRef}>
              <BuyersList selectedAuctionId={selectedAuctionId} />
            </BuyersColumn>
            <LotsColumn ref={lotsColumnRef}>
              {goodsHandoversLoading || goodsHandoversAdminLoading ? (
                <Loader active big />
              ) : (
                <LotsList showLotLightbox={showLotLightbox} />
              )}
            </LotsColumn>
            <DetailsColumn ref={detailsColumnRef}>
              {commentAssignment ? (
                <CommentColumn assignment={commentAssignment} />
              ) : (
                <>
                  {forPickup ? (
                    <PickupColumn showLotLightbox={showLotLightbox} />
                  ) : (
                    <DeliveredColumn showLotLightbox={showLotLightbox} />
                  )}
                </>
              )}
            </DetailsColumn>
          </>
        )}
      </GoodsHandoverBody>
      {lightboxLot ? (
        <LotLightbox
          lot={lightboxLot}
          open={lightboxOpen}
          closeLightbox={() => setLightboxOpen(false)}
        />
      ) : (
        ""
      )}
    </GoodsHandoverContainer>
  );
};

export default GoodsHandover;
