import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "vavato-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import {
  selectSelectedAssignments,
  selectSelectedGoodsHandovers,
  goodsHandoverForPickup,
} from "../store/goodsHandoverSlice";

const Container = styled.div`
  display: none;
  background: white;

  @media only screen and (max-width: 768px) {
    position: relative;
    height: 50px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -8px 6px -6px rgba(0, 0, 0, 0.25);
    margin-left: -10px;
    margin-right: -10px;
    bottom: -20px;
  }
`;

const Message = styled.div`
  display: flex;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.primary};
`;

const Text = styled.div`
  font-weight: 100;
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 0;
`;

const CheckoutFooter = ({ buttonText, onClick, disabled = false }) => {
  const { t } = useTranslation("goodsHandover");

  const forPickup = useSelector(goodsHandoverForPickup);

  const selectedAssignments = useSelector(selectSelectedAssignments);

  const selectedGoodsHandovers = useSelector(selectSelectedGoodsHandovers);

  if (!selectedAssignments.length || !forPickup) return null;

  let message;
  if (selectedGoodsHandovers.length > 1) {
    message = t("checkoutMessageWithMultipleBuyers", {
      lotsCount: selectedAssignments.length,
      buyersCount: selectedGoodsHandovers.length,
      interpolation: { escapeValue: false },
    });
  } else {
    message = t("checkoutMessageWithSingleBuyer", {
      count: selectedAssignments.length,
      interpolation: { escapeValue: false },
    });
  }

  return (
    <Container>
      <Message>
        <Icon icon={faShoppingCart} />
        <Text dangerouslySetInnerHTML={{ __html: message }} />
      </Message>
      <StyledButton onClick={onClick} disabled={disabled}>
        {buttonText}
      </StyledButton>
    </Container>
  );
};

export default CheckoutFooter;
