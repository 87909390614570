import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../api-service";

import { analytics } from "../firebase";
import { logEvent, setUserProperties } from "firebase/analytics";

import { DEFAULT_LANGUAGE } from "../constants";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (_, { getState }) => {
    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(`${domain}/seller/profile`);

    let data = { email: null, name: null, admin: false, roles: [] };

    if (result.ok) data = result.json();

    setUserProperties(analytics, {
      admin: data.admin,
      uid: window.localStorage.getItem("uid"),
      locale: getState().profile.language,
    });

    return data;
  }
);

export const getFeatures = createAsyncThunk(
  "profile/getFeatures",
  async (_, { getState }) => {
    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(`${domain}/features`);

    if (!result.ok) {
      return {};
    }

    return result.json();
  }
);

export const selectLanguage = createAction(
  "profile/selectLanguage",
  (language) => {
    window.localStorage.setItem("language", language);

    logEvent(analytics, "language_change", {
      locale: language,
    });

    setUserProperties(analytics, {
      locale: language,
    });

    return {
      payload: { language },
    };
  }
);

const initialState = {
  language: DEFAULT_LANGUAGE,
  features: {},
  email: null,
  name: null,
  canImpersonate: null,
  loading: true,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfile.pending]: (state, action) => {
      state.loading = true;
      state.canImpersonate = false;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.canImpersonate = action.payload.admin;
      state.roles = action.payload.roles;
    },
    [getProfile.rejected]: (state, action) => {
      state.loading = false;
      state.canImpersonate = false;
    },
    [selectLanguage]: (state, action) => {
      state.language = action.payload.language || DEFAULT_LANGUAGE;
    },
    [getFeatures.fulfilled]: (state, action) => {
      state.features = action.payload;
    },
    [getFeatures.rejected]: (state, action) => {
      state.features = {};
    },
  },
});

export const selectLoadingProfile = (state) => state.profile.loading;
export const impersonateAbility = (state) => state.profile.canImpersonate;
export const selectCurrentLanguage = (state) => state.profile.language;
export const selectAdvancedDeliverForm = (state) =>
  !!state.profile.features.seller_advanced_form;
export const selectCanDebrief = (state) =>
  !!state.profile.features.seller_debrief;
export const selectUserRoles = (state) => state.profile.roles;

export default profileSlice.reducer;
