import Dexie from "dexie";

import { postData } from "../api-service";

import { INDEXED_DB_VERSION } from "../constants";
import { upload } from "../utils/ActiveStorageUploader";
import { blobToDataUrl } from "../utils/ActiveJS";

const fetchOfflineQueues = () => {
  const db = new Dexie("GoodsHandovers");

  db.version(INDEXED_DB_VERSION).stores({
    deliveries:
      "++id,buyerEntityId,assignmentIds,deliveredBy,transporterCompany,transporterLicensePlate,transporterName",
    buyerProofs:
      "++id,buyerEntityId,signatureSignedId,signatureFile,idProofSignedId,idProofFile",
  });

  db.tables.forEach((table) => {
    db[table.name].upsert = async (ref, payload) => {
      const existingRow = await table.get(ref);

      if (existingRow) {
        table.update(existingRow.id, payload);
      } else {
        table.add({ ...payload, ...ref });
      }
    };
  });

  return db;
};

export const offlineQueues = fetchOfflineQueues();

export const syncOffline = async (offlineDelivery, domain, extraHeaders) => {
  const buyerProofs = await offlineQueues.buyerProofs.get({
    buyerEntityId: offlineDelivery.buyerEntityId,
  });

  const params = {
    assignment_ids: offlineDelivery.assignmentIds,
    delivered_by: offlineDelivery.deliveredBy,
    transporter_company_name: offlineDelivery.transporterCompany,
    transporter_license_plate_number: offlineDelivery.transporterLicensePlate,
    transporter_name: offlineDelivery.transporterName,
  };

  if (buyerProofs?.idProofSignedId) {
    params.id_proof = buyerProofs.idProofSignedId;
  } else if (buyerProofs?.idProofFile) {
    const idProofBlob = buyerProofs.idProofFile;
    // blob name is not saved in IndexedDB when saving a blob
    idProofBlob.name = "id";
    params.id_proof = await upload(idProofBlob, extraHeaders);
  }

  if (buyerProofs?.signatureSignedId) {
    params.signature = buyerProofs.signatureSignedId;
  } else if (buyerProofs?.signatureFile) {
    const signatureBlob = buyerProofs.signatureFile;
    // blob name is not saved in IndexedDB when saving a blob
    signatureBlob.name = `signature-${buyerProofs.buyerEntityId}`;
    params.signature = await upload(signatureBlob, extraHeaders);
  }

  const result = await postData(
    `${domain}/seller/goods_handover_deliveries`,
    params,
    extraHeaders
  );

  return result.ok;
};

export const retrieveIdProofFromOfflineQueue = async (
  buyerIds,
  state,
  goodsHandoverAdapter,
  extraHeaders
) => {
  const signedIds = await Promise.all(
    buyerIds.map(async (buyerEntityId) => {
      const buyerProofs = await offlineQueues.buyerProofs.get({
        buyerEntityId: buyerEntityId,
      });

      if (!buyerProofs) return;

      const idProofBlob = buyerProofs.idProofFile;

      if (idProofBlob) {
        try {
          // blob name is not saved in IndexedDB when saving a blob
          idProofBlob.name = "id";
          const signedId = await upload(idProofBlob, extraHeaders);

          const url = await blobToDataUrl(idProofBlob);

          goodsHandoverAdapter.updateOne(state, {
            id: buyerEntityId,
            changes: { id_proof: { signed_id: signedId, url: url } },
          });

          return signedId;
        } catch (_exception) {
          // Fail to upload again, so it will also fail to deliver, do nothing,
          // all will be taken care of in manual "Sync Offline handovers"
        }
      }
    })
  );

  return signedIds.filter(Boolean)[0];
};

export const storeFailedDelivery = async (assignmentIdsByBuyerId, params) => {
  await Promise.all(
    Object.entries(assignmentIdsByBuyerId).map(
      async ([buyerEntityId, assignmentIds]) => {
        await offlineQueues.buyerProofs.upsert(
          { buyerEntityId: parseInt(buyerEntityId) },
          {
            signatureSignedId: params.signature,
            idProofSignedId: params.id_proof,
          }
        );

        await offlineQueues.deliveries.add({
          buyerEntityId: parseInt(buyerEntityId),
          assignmentIds: assignmentIds,
          deliveredBy: params.delivered_by,
          transporterCompany: params.transporter_company_name,
          transporterLicensePlate: params.transporter_license_plate_number,
          transporterName: params.transporter_name,
        });
      }
    )
  );
};

export const storeFailedSignatureUpload = async (
  buyerEntityId,
  signatureBlob
) => {
  await offlineQueues.buyerProofs.upsert(
    { buyerEntityId: buyerEntityId },
    { signatureFile: signatureBlob }
  );
};

export const storeFailedIdProofUpload = async (
  buyerEntityId,
  idProofBlob,
  signedId
) => {
  await offlineQueues.buyerProofs.upsert(
    { buyerEntityId: buyerEntityId },
    {
      idProofFile: idProofBlob,
      idProofSignedId: signedId,
    }
  );
};
