import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "vavato-ui";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { localizedRoutingRegex } from "../utils";

import { reset } from "../store/goodsHandoverSlice";

import { selectLanguage, selectCurrentLanguage } from "../store/profileSlice";

const SidebarContainer = styled.nav`
  background-color: #f8f9fa;
  padding-top: 10px;
  flex: 0 0 230px;

  @media only screen and (max-width: 768px) {
    position: absolute;
    height: calc(100vh - 40px - 10px);
    z-index: 1;
    width: 230px;
  }
`;

const SidebarList = styled.div`
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 40px - 10px - 80px);
  height: 100%;
  overflow-y: auto;
`;

const SidebarItems = styled.ul`
  list-style-type: none;
  padding-left: 24px;
`;

const SidebarItem = styled.li`
  color: ${(props) => props.theme.primary};
  font-size: 12px;
  letter-spacing: 1px;
  height: 3em;
  text-transform: uppercase;

  & a,
  & a:hover,
  & a:visited,
  & a:active {
    color: ${(props) => props.theme.primary} !important;
    text-decoration: none !important;
    font-size: 12px;
    letter-spacing: 1px;
  }
`;

const MainCategory = styled.div`
  height: 3em;
  cursor: pointer;
`;

const FixedFooter = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  justify-content: center;
`;

const Sidebar = () => {
  const { t, i18n } = useTranslation("sidebar");
  const dispatch = useDispatch();
  const history = useHistory();

  const [showGoodsHandoverSubItems, setShowGoodsHandoverSubItems] =
    useState(true);

  const currentLanguage = useSelector(selectCurrentLanguage);

  const changeLanguage = (language) => {
    dispatch(selectLanguage(language));
    i18n.changeLanguage(language);

    const newPathname = history.location.pathname.replace(
      localizedRoutingRegex(currentLanguage),
      `/${language}$1`
    );

    history.push(`${newPathname}${history.location.search}`);
  };

  return (
    <SidebarContainer>
      <SidebarList>
        <SidebarItems>
          <SidebarItem>
            <Link to={`/${currentLanguage}/auctions`}>{t("auctions")}</Link>
          </SidebarItem>
          <SidebarItem>
            <MainCategory
              onClick={() =>
                setShowGoodsHandoverSubItems(!showGoodsHandoverSubItems)
              }
            >
              {t("goodsHandover.category")}
            </MainCategory>
            {showGoodsHandoverSubItems ? (
              <SidebarItems>
                <SidebarItem>
                  <Link
                    to={`/${currentLanguage}`}
                    onClick={() => dispatch(reset())}
                  >
                    {t("goodsHandover.toBeDelivered")}
                  </Link>
                </SidebarItem>
                <SidebarItem>
                  <Link
                    to={`/${currentLanguage}/delivered`}
                    onClick={() => dispatch(reset())}
                  >
                    {t("goodsHandover.delivered")}
                  </Link>
                </SidebarItem>
              </SidebarItems>
            ) : (
              ""
            )}
          </SidebarItem>
        </SidebarItems>
      </SidebarList>
      <FixedFooter>
        <Button primary onClick={() => changeLanguage("en")}>
          EN
        </Button>
        <Button primary onClick={() => changeLanguage("nl")}>
          NL
        </Button>
        <Button primary onClick={() => changeLanguage("fr")}>
          FR
        </Button>
      </FixedFooter>
    </SidebarContainer>
  );
};

export default Sidebar;
