import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table, formatCurrency } from "vavato-ui";
import { assignmentStatusLabel } from "../utils/ui";

import FoldableTable from "../components/FoldableTable";
import LotLinkWithImage from "../components/LotLinkWithImage";
import LotBidder from "../components/LotBidder";

import { selectCurrentLanguage } from "../store/profileSlice";

const CloseLotsTable = ({ lots, title, startOpen = true }) => {
  const { t } = useTranslation("auctions");

  const currentLanguage = useSelector(selectCurrentLanguage);

  if (!lots.length) return null;

  return (
    <FoldableTable title={title} startOpen={startOpen}>
      <Table>
        <thead>
          <tr>
            <th>{t("headers.lotNr")}</th>
            <th>{t("headers.title")}</th>
            <th>{t("headers.category")}</th>
            <th>{t("headers.tempAlloc")}</th>
            <th>{t("headers.bidder")}</th>
            <th className="number">{t("headers.expected")}</th>
            <th className="number">{t("headers.opening")}</th>
            <th className="number">{t("headers.final")}</th>
            <th className="number">{t("headers.bids")}</th>
            <th className="number">{t("headers.paymentStatus")}</th>
          </tr>
        </thead>
        <tbody>
          {lots.map((lot) => (
            <tr key={lot.id}>
              <td>{lot.lot_number}</td>
              <td>
                <LotLinkWithImage
                  lotUrl={lot.url}
                  LotName={lot.name_translations[currentLanguage]}
                  imageUrl={
                    lot.image_urls?.length ? lot.image_urls[0]?.small : null
                  }
                />
              </td>
              <td>{lot.categories[0].name_translations[currentLanguage]}</td>
              <td>{lot.temporary_assignment ? t("yes") : t("no")}</td>
              <td>
                <LotBidder
                  bidderName={lot.assignment.buyer_entity.user_name}
                  countryCode={lot.assignment.buyer_entity.country_code}
                  countryName={
                    lot.assignment.buyer_entity.country_name_translations[
                      currentLanguage
                    ]
                  }
                />
              </td>
              <td className="number">
                {formatCurrency(lot.expected_sale_price)}
              </td>
              <td className="number">
                {formatCurrency(lot.opening_bid_price)}
              </td>
              <td className="number">{formatCurrency(lot.assignment.price)}</td>
              <td className="number">{lot.bids_count}</td>
              <td className="number">
                {assignmentStatusLabel(lot.assignment.status, t)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </FoldableTable>
  );
};

export default CloseLotsTable;
