import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "vavato-ui";

import { groupBy } from "../utils/ActiveJS";

import { loadingAuction, selectLots } from "../store/auctionsSlice";
import { selectCurrentLanguage } from "../store/profileSlice";

const BuyerLocationsStatsTable = () => {
  const { t } = useTranslation("debrief");

  const currentLanguage = useSelector(selectCurrentLanguage);

  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  if (loading) return null;

  const lotsByBuyerCountry = Array.from(
    groupBy(
      lots,
      (lot) =>
        lot.assignment?.buyer_entity?.country_name_translations[currentLanguage]
    )
  )
    .filter(([country, _lots]) => country)
    .sort(([_country, lots]) => -lots.length);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("buyerLocations")}</th>
          <th className="number">{t("total")}</th>
        </tr>
      </thead>
      <tbody>
        {lotsByBuyerCountry.map(([country, lots]) => (
          <tr key={country}>
            <td>{country}</td>
            <td className="number">{lots.length}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BuyerLocationsStatsTable;
