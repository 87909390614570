import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { rgba } from "polished";
import { Button, FormField, SearchField } from "vavato-ui";

import ListHeading from "./ListHeading";
import ScrollableList from "./ScrollableList";
import ColumnSearchContainer from "./ColumnSearchContainer";
import CommentIssues from "./CommentIssues";

import {
  setCommentAssignment,
  saveCommentAssignment,
} from "../store/goodsHandoverSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

const InvisibleLotHeader = styled.div`
  visibility: hidden;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  padding: 0 10px;
  border-top: 2px solid ${(props) => rgba(props.theme.secondary, 0.3)};

  @media only screen and (max-width: 768px) {
    border-top: none;
  }
`;

const Header = styled.h4`
  margin: 10px 0px;
`;

const Image = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  & img {
    max-width: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  line-height: 1.2em;
  max-height: 3.6em;
  font-size: 11px;
  border-top: 0.5px solid ${(props) => rgba(props.theme.secondary, 0.3)};
  padding: 0px 10px;
  width: 100%;
`;

const Overtitle = styled.div`
  color: ${(props) => props.theme.text};
  padding-top: 10px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.primary};
`;

const AssignmentSummary = styled.div`
  display: flex;
  margin-bottom: 32px;
  padding: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  @media only screen and (max-width: 768px) {
    justify-content: end;
  }
`;

const CancelButton = styled(Button)`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const CommentColumn = ({ assignment }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const issueTypeRef = useRef(null);
  const commentsRef = useRef(null);

  const currentLanguage = useSelector(selectCurrentLanguage);

  return (
    <>
      <InvisibleLotHeader>
        <ColumnSearchContainer header={t("searchLotTitle")}>
          <SearchField placeholder={t("searchLotPlaceholder")} />
        </ColumnSearchContainer>
        <ListHeading>{t("comment.title")}</ListHeading>
      </InvisibleLotHeader>
      <ScrollableList className="comment">
        <Container>
          <Header>{t("comment.header")}</Header>
          <AssignmentSummary>
            <Image>
              <img
                src={assignment.lot.image_urls[0]?.small}
                alt={t("lotAlternativeText")}
              />
            </Image>
            <Info>
              <Overtitle>
                {assignment.auction.id}.{assignment.lot.number}
              </Overtitle>
              <Title>{assignment.lot.name_translations[currentLanguage]}</Title>
            </Info>
          </AssignmentSummary>
          <FormField name="issue" label={t("comment.issueLabel")}>
            <select
              name="issue"
              id="issue"
              ref={issueTypeRef}
              defaultValue={assignment.issue_type}
            >
              <CommentIssues />
            </select>
          </FormField>
          <FormField name="comment" label={t("comment.commentLabel")}>
            <textarea
              name="comment"
              placeholder={t("comment.placeholder")}
              ref={commentsRef}
              defaultValue={assignment.comments}
            />
          </FormField>
          <ButtonGroup>
            <CancelButton
              className="outline"
              onClick={() => dispatch(setCommentAssignment(null))}
            >
              {t("cancel")}
            </CancelButton>
            <Button
              onClick={() =>
                dispatch(
                  saveCommentAssignment({
                    id: assignment.id,
                    issueType: issueTypeRef.current.value,
                    comments: commentsRef.current.value,
                  })
                )
              }
            >
              {t("save")}
            </Button>
          </ButtonGroup>
        </Container>
      </ScrollableList>
    </>
  );
};

export default CommentColumn;
