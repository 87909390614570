import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import styled from "styled-components";

import { getFeatures, getProfile } from "./store/profileSlice";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import LocalizedRoute from "./components/LocalizedRoute";
import GoodsHandover from "./containers/GoodsHandover";
import AuctionsOverview from "./containers/AuctionsOverview";
import Debrief from "./containers/Debrief";
import { getOrSetJwt } from "./api-service";
import { JwtProvider } from "./hooks/contexts/JwtContext";

import { MOBILE_NAVIGATION_WIDTH } from "./constants";

const Main = styled.div`
  display: flex;
  height: calc(100vh - 40px);
`;

function App() {
  const dispatch = useDispatch();

  getOrSetJwt();
  const [cookies] = useCookies();

  const [open, setOpen] = useState(
    window.innerWidth <= MOBILE_NAVIGATION_WIDTH ? false : true
  );
  const [title, setTitle] = useState(null);

  // Set localStorage from cookies for use with useFetch and other vavato-ui API functions
  useEffect(() => {
    Object.entries(cookies).forEach(
      (cookie) => (window.localStorage[cookie[0]] = cookie[1])
    );
  }, [cookies]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getFeatures());
  }, [dispatch]);

  return (
    <Router>
      <Navbar open={open} setOpen={setOpen} title={title} />
      <Main>
        {open ? <Sidebar /> : ""}
        <JwtProvider>
          <Switch>
            <LocalizedRoute exact path="/:language([a-z]{2})/delivered">
              <GoodsHandover setTitle={setTitle} />
            </LocalizedRoute>
            <LocalizedRoute exact path="/:language([a-z]{2})/auctions">
              <AuctionsOverview setTitle={setTitle} />
            </LocalizedRoute>
            <LocalizedRoute exact path="/:language([a-z]{2})/debrief">
              <Debrief setTitle={setTitle} />
            </LocalizedRoute>
            <LocalizedRoute path="/:language([a-z]{2})?">
              <GoodsHandover forPickup setTitle={setTitle} />
            </LocalizedRoute>
          </Switch>
        </JwtProvider>
      </Main>
    </Router>
  );
}

export default App;
