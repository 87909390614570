import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Loader } from "vavato-ui";

import CloseLotsTable from "../components/CloseLotsTable";
import OpenLotsTable from "../components/OpenLotsTable";

import { loadingAuction, selectLots } from "../store/auctionsSlice";

const CloseLotsTableContainer = () => {
  const { t } = useTranslation("auctions");

  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  if (loading) return <Loader big active />;

  const groupedLots = lots.reduce(
    (lots, lot) => {
      if (!lot.assignment) {
        lots.assignless.push(lot);
      } else if (lot.assignment.paid) {
        lots.paid.push(lot);
      } else if (lot.assignment.delivered) {
        lots.delivered.push(lot);
      } else {
        lots.remaining.push(lot);
      }

      return lots;
    },
    { paid: [], delivered: [], remaining: [], assignless: [] }
  );

  return (
    <>
      <CloseLotsTable
        lots={groupedLots.paid}
        title={t("lotsPaidToBeDelivered", { total: groupedLots.paid.length })}
      />
      <CloseLotsTable
        lots={groupedLots.remaining}
        title={t("lotsUnpaid", { total: groupedLots.remaining.length })}
      />
      <CloseLotsTable
        lots={groupedLots.delivered}
        title={t("lotsPaidDelivered", { total: groupedLots.delivered.length })}
      />
      <OpenLotsTable
        startOpen={false}
        lots={groupedLots.assignless}
        title={t("lotsWithoutBids", { total: groupedLots.assignless.length })}
      />
    </>
  );
};

export default CloseLotsTableContainer;
