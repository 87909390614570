import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { List, SearchField } from "vavato-ui";

import LotItem from "./LotItem";
import ColumnSearchContainer from "./ColumnSearchContainer";
import EmptyState from "./EmptyState";
import ListHeading from "./ListHeading";
import CheckoutFooter from "./CheckoutFooter";
import ScrollableList from "./ScrollableList";

import {
  selectSelectedBuyerId,
  selectGoodsHandoverById,
  selectSelectedAssignments,
  filteredCurrentAssignmentsByLotQuery,
  setLotQuery,
  setCurrentColumn,
} from "../store/goodsHandoverSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

const StyledList = styled(List)`
  margin: 0;
`;

const SearchContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ColumnSearchBar = ({ header, placeholder, searchKey }) => {
  const dispatch = useDispatch();

  const query = useSelector((state) => state.goodsHandover.lotQuery);

  useEffect(() => dispatch(setLotQuery("")), [dispatch, searchKey]);

  return (
    <SearchContainer>
      <ColumnSearchContainer header={header} key={searchKey}>
        <SearchField
          value={query}
          placeholder={placeholder}
          onChange={(value) => dispatch(setLotQuery(value))}
        ></SearchField>
      </ColumnSearchContainer>
    </SearchContainer>
  );
};

const LotsSubList = ({
  title,
  assignments,
  totalAssignments,
  showLotLightbox,
}) => {
  if (!assignments.length) return null;

  return (
    <>
      <ListHeading>
        {`${assignments.length}/${totalAssignments}`} {title}
      </ListHeading>
      <StyledList>
        {assignments.map((assignment) => (
          <LotItem
            key={assignment.id}
            assignment={assignment}
            showLotLightbox={showLotLightbox}
          />
        ))}
      </StyledList>
    </>
  );
};

const LotsList = ({ showLotLightbox }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const currentLanguage = useSelector(selectCurrentLanguage);

  const selectedAssignments = useSelector(selectSelectedAssignments);

  const selectedBuyerId = useSelector(selectSelectedBuyerId);
  const selectedGoodsHandover = useSelector((state) =>
    selectGoodsHandoverById(state, selectedBuyerId)
  );

  const filteredAssignments = useSelector((state) =>
    filteredCurrentAssignmentsByLotQuery(state, currentLanguage)
  );

  if (!selectedBuyerId) {
    return (
      <>
        <ColumnSearchBar
          header={t("searchLotTitle")}
          placeholder={t("searchLotPlaceholder")}
        />
        <EmptyState>
          <h3>{t("emptyLotsTitle")}</h3>
          <p>{t("emptyLotsDescription")}</p>
        </EmptyState>
      </>
    );
  }

  const groupedAssignments = filteredAssignments.reduce(
    (assignments, assignment) => {
      if (assignment.payment_status === "delivered") {
        assignments.delivered.push(assignment);
      } else if (assignment.payment_status === "paid") {
        assignments.paid.push(assignment);
      } else {
        assignments.unpaid.push(assignment);
      }

      return assignments;
    },
    { delivered: [], paid: [], unpaid: [] }
  );

  const totalAssignments = selectedGoodsHandover?.assignments?.length || 0;

  return (
    <>
      <ColumnSearchBar
        header={t("searchLotTitle")}
        placeholder={t("searchLotPlaceholder")}
        searchKey={selectedBuyerId}
      />
      <ScrollableList
        className={`lots ${selectedAssignments.length ? "with-footer" : ""}`}
      >
        <LotsSubList
          title={t("delivered")}
          assignments={groupedAssignments.delivered}
          totalAssignments={totalAssignments}
          showLotLightbox={showLotLightbox}
        />
        <LotsSubList
          title={t("readyForPickup")}
          assignments={groupedAssignments.paid}
          totalAssignments={totalAssignments}
          showLotLightbox={showLotLightbox}
        />
        <LotsSubList
          title={t("paymentPending")}
          assignments={groupedAssignments.unpaid}
          totalAssignments={totalAssignments}
          showLotLightbox={showLotLightbox}
        />
      </ScrollableList>
      <CheckoutFooter
        buttonText={t("checkout")}
        onClick={() => dispatch(setCurrentColumn("details"))}
      />
    </>
  );
};

export default LotsList;
