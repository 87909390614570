import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table, Loader, formatCurrency } from "vavato-ui";

import { lotStatusLabel } from "../utils/ui";

import { loadingAuction, selectLots } from "../store/auctionsSlice";

import { selectCurrentLanguage } from "../store/profileSlice";

import LotLinkWithImage from "../components/LotLinkWithImage";

const LotsTable = () => {
  const { t } = useTranslation("auctions");

  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  const currentLanguage = useSelector(selectCurrentLanguage);

  if (loading) return <Loader big active />;

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("headers.lotNr")}</th>
          <th>{t("headers.title")}</th>
          <th>{t("headers.category")}</th>
          <th>{t("headers.tempAlloc")}</th>
          <th className="number">{t("headers.minimumPrice")}</th>
          <th className="number">{t("headers.openingPrice")}</th>
          <th className="number">{t("headers.auctionStatus")}</th>
        </tr>
      </thead>
      <tbody>
        {lots.map((lot) => (
          <tr key={lot.id}>
            <td>{lot.lot_number}</td>
            <td>
              <LotLinkWithImage
                lotUrl={lot.url}
                LotName={lot.name_translations[currentLanguage]}
                imageUrl={
                  lot.image_urls?.length ? lot.image_urls[0]?.small : null
                }
              />
            </td>
            <td>{lot.categories[0].name_translations[currentLanguage]}</td>
            <td>{lot.temporary_assignment ? t("yes") : t("no")}</td>
            <td className="number">
              {formatCurrency(lot.expected_sale_price)}
            </td>
            <td className="number">
              {formatCurrency(lot.opening_bid_price || 0)}
            </td>
            <td className="number">{lotStatusLabel(lot.status, t)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default LotsTable;
