import styled from "styled-components";

const EmptyBar = styled.div`
  background-color: #e6e6e6;
  border-radius: 10px;
  overflow: hidden;
  height: 20px;
`;

const CompletedBar = styled.div`
  background-color: ${(props) => props.theme.secondary};
  height: 20px;
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  color: #ffffff;
`;

const ProgressBar = ({ completed, total }) => {
  const completedWidth = Math.round((100.0 * completed) / total);

  return (
    <EmptyBar>
      <CompletedBar style={{ width: `${completedWidth}%` }}>
        {completed} / {total}
      </CompletedBar>
    </EmptyBar>
  );
};
export default ProgressBar;
