import styled from "styled-components";
import { useTranslation } from "react-i18next";

import OnlineIndicator from "./OnlineIndicator";

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const PageTitle = styled.h1`
  margin: 10px 0 !important;
`;

const GoodsHandoverTitle = (props) => {
  const { t } = useTranslation("goodsHandover");

  return (
    <Title>
      <PageTitle>{t("goodsHandover")}</PageTitle>
      <OnlineIndicator />
      {props.children}
    </Title>
  );
};

export default GoodsHandoverTitle;
