import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Table, formatCurrency } from "vavato-ui";

import {
  fetchAuctionStats,
  selectStats,
  selectSelectedAuction,
} from "../store/auctionsSlice";

const SubHeader = styled.tr`
  border-bottom: 1px solid black !important;
`;

const OpenAuctionStatsTable = () => {
  const { t } = useTranslation("auctions");

  const dispatch = useDispatch();

  const stats = useSelector(selectStats);
  const currentAuction = useSelector(selectSelectedAuction);

  useEffect(() => {
    dispatch(fetchAuctionStats({ auctionId: currentAuction.id }));
  }, [dispatch, currentAuction]);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("statistics")}</th>
        </tr>
      </thead>
      <tbody>
        <SubHeader>
          <td>{t("hour")}</td>
          <td></td>
          <td>{formatCurrency(stats.succeeded_bids_last_hour_value || 0)}</td>
        </SubHeader>
        <tr>
          <td>{t("bids")}</td>
          <td>{stats.succeeded_bids_last_hour_count}</td>
          <td />
        </tr>
        <tr>
          <td>{t("bidders")}</td>
          <td>{stats.succeeded_bidders_last_hour_count}</td>
          <td />
        </tr>
        <tr />
        <SubHeader>
          <td>{t("day")}</td>
          <td></td>
          <td>{formatCurrency(stats.succeeded_bids_last_day_value || 0)}</td>
        </SubHeader>
        <tr>
          <td>{t("bids")}</td>
          <td>{stats.succeeded_bids_last_day_count}</td>
          <td />
        </tr>
        <tr>
          <td>{t("bidders")}</td>
          <td>{stats.succeeded_bidders_last_day_count}</td>
          <td />
        </tr>
        <tr />
        <SubHeader>
          <td>{t("week")}</td>
          <td></td>
          <td>{formatCurrency(stats.succeeded_bids_last_week_value || 0)}</td>
        </SubHeader>
        <tr>
          <td>{t("bids")}</td>
          <td>{stats.succeeded_bids_last_week_count}</td>
          <td />
        </tr>
        <tr>
          <td>{t("bidders")}</td>
          <td>{stats.succeeded_bidders_last_week_count}</td>
          <td />
        </tr>
      </tbody>
    </Table>
  );
};

export default OpenAuctionStatsTable;
