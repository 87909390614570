import PropTypes from "prop-types";
import React, { createContext } from "react";
import { checkJwtExpiration } from "../../api-service";

const JwtContext = createContext();

function JwtProvider({ children }) {
  if (localStorage.getItem("jwt")) {
    checkJwtExpiration();
  }

  return <JwtContext.Provider value="">{children}</JwtContext.Provider>;
}

JwtProvider.propTypes = {
  children: PropTypes.any,
};

export { JwtProvider };
