import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "vavato-ui";

import { selectCurrentLanguage, selectCanDebrief } from "../store/profileSlice";
import {
  fetchAuction,
  selectAuctionById,
  fetchAuctionAssociations,
  selectAssociations,
} from "../store/auctionsSlice";

const StartDebriefButton = ({ selectedAuctionId }) => {
  const { t } = useTranslation("debrief");

  const currentLanguage = useSelector(selectCurrentLanguage);

  const dispatch = useDispatch();

  const [buttonVisible, setButtonVisible] = useState(false);

  const auction = useSelector((state) =>
    selectAuctionById(state, selectedAuctionId)
  );

  const fetchMissingAuction = useCallback(() => {
    dispatch(fetchAuction({ id: selectedAuctionId }));
  }, [dispatch, selectedAuctionId]);

  useEffect(() => {
    if (!selectedAuctionId) return;
    if (!auction) {
      fetchMissingAuction();
    }
  }, [auction, selectedAuctionId, fetchMissingAuction]);

  useEffect(() => {
    if (!auction) return;
    dispatch(fetchAuctionAssociations({ auctionId: auction.id }));
  }, [dispatch, auction]);

  const associations = useSelector(selectAssociations);

  const computeButtonVisible = useCallback(() => {
    if (!selectedAuctionId) return false;
    if (!auction) return false;

    const releaseDatesInThePast = new Date(auction.release_to) < new Date();
    const hideDateInTheFuture = new Date() < new Date(auction.hide_date);
    const noDebriefYet = !associations.debrief_id;
    const hasDeliveredLots = !!associations.delivered_lots_count;

    setButtonVisible(
      noDebriefYet &&
        (releaseDatesInThePast || hasDeliveredLots) &&
        hideDateInTheFuture
    );
  }, [selectedAuctionId, auction, associations]);

  useEffect(() => {
    computeButtonVisible();
  }, [computeButtonVisible]);

  const canDebrief = useSelector(selectCanDebrief);
  if (!canDebrief) return "";

  return (
    <>
      {buttonVisible ? (
        <Link to={`/${currentLanguage}/debrief?auctionId=${selectedAuctionId}`}>
          <Button>{t("startDebriefButton")}</Button>
        </Link>
      ) : (
        ""
      )}
    </>
  );
};

export default StartDebriefButton;
