// Why ActiveJS.js? This collection is about decorating some JS standard
// to compensate for the standard lib. A bit like Active Support in rails
// thus the name

export const getBlob = async (canvas, filename) => {
  const blob = await new Promise((resolve) => canvas.toBlob(resolve));
  blob.name = filename;
  return blob;
};

export const blobToDataUrl = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result);
    reader.onerror = (_e) => reject(reader.error);
    reader.readAsDataURL(blob);
  });
};

export const downloadBlob = (blob, filename) => {
  const objectUrl = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = objectUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(objectUrl);
};

export const groupBy = (list, func) => {
  const map = new Map();
  list.forEach((item) => {
    const key = func(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
