import { forwardRef } from "react";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";

const CountryDropdown = forwardRef(({ name, placeholder, disabled }, ref) => {
  countries.registerLocale(en);

  const alpha3Codes = countries.getAlpha3Codes();

  const countryName = (alpha3) => {
    return countries.getName(alpha3Codes[alpha3], "en", { select: "official" });
  };

  return (
    <select name={name} ref={ref} disabled={disabled}>
      <option key="default" value="">
        {placeholder}
      </option>
      {Object.keys(alpha3Codes).map((alpha3Code) => (
        <option key={alpha3Code} value={alpha3Code}>
          {countryName(alpha3Code)}
        </option>
      ))}
    </select>
  );
});

export default CountryDropdown;
