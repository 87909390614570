import { useEffect, useState } from "react";
import styled from "styled-components";

const OnlineIndicatorElement = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #62bd19;
  border-radius: 50%;
  margin-left: 5px;

  &.offline {
    background-color: red;
  }
`;

const OnlineIndicator = () => {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", () => setOnline(false), false);
    window.addEventListener("online", () => setOnline(true), false);
  }, [setOnline]);

  return <OnlineIndicatorElement className={online ? "" : "offline"} />;
};

export default OnlineIndicator;
