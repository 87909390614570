import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import { fetchData, putData, postData } from "../api-service";

import { downloadBlob } from "../utils/ActiveJS";

import { extraReqHeaders, handleErrors } from "../utils/api";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

import { success, error } from "./toasterSlice";

const auctionsAdapter = createEntityAdapter();

const initialState = auctionsAdapter.getInitialState({
  loading: true,
  selectedAuctionId: null,
  lots: [],
  stats: {},
  associations: {},
});

export const fetchAuctions = createAsyncThunk(
  "auctions/fetchAuctions",
  async (_, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(`${domain}/seller/auctions`, extraHeaders);

    handleErrors(result, "Error fetching auctions", dispatch, error);

    return result.json();
  }
);

export const fetchAuction = createAsyncThunk(
  "auctions/fetchAuction",
  async ({ id }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}/seller/auctions/${id}`,
      extraHeaders
    );

    handleErrors(result, `Error fetching auction ${id}`, dispatch, error);

    return result.json();
  }
);

export const fetchLots = createAsyncThunk(
  "auctions/fetchLots",
  async ({ auctionId }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}/seller/auctions/${auctionId}/lots`,
      extraHeaders
    );

    handleErrors(result, "Error fetching lots", dispatch, error);

    logEvent(analytics, "view_auction", {
      auction_id: auctionId,
    });

    return result.json();
  }
);

export const exportLots = createAsyncThunk(
  "auctions/exportLots",
  async ({ auctionId }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}/seller/auctions/${auctionId}/lots_export`,
      { ...extraHeaders, "Content-Type": "application/csv" }
    );

    handleErrors(result, "Error exporting lots", dispatch, error);

    logEvent(analytics, "download_lots_csv", {
      auction_id: auctionId,
    });

    downloadBlob(await result.blob(), `auction-${auctionId}.csv`);

    return;
  }
);

export const exportInvoices = createAsyncThunk(
  "auctions/exportInvoices",
  async ({ auctionId }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}/seller/auctions/${auctionId}/invoices_export`,
      { ...extraHeaders, "Content-Type": "application/pdf" }
    );

    handleErrors(result, "Error exporting invoices", dispatch, error);

    logEvent(analytics, "download_invoices", {
      auction_id: auctionId,
    });

    downloadBlob(await result.blob(), `auction-${auctionId}-invoices.pdf`);

    return;
  }
);

export const fetchAuctionStats = createAsyncThunk(
  "auctions/fetchAuctionStats",
  async ({ auctionId }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}/seller/auction_stats/${auctionId}`,
      extraHeaders
    );

    handleErrors(result, "Error fetching stats", dispatch, error);

    return result.json();
  }
);

export const fetchAuctionAssociations = createAsyncThunk(
  "auctions/fetchAuctionAssociations",
  async ({ auctionId }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const domain = getState().environment.REACT_APP_API_URL;
    const result = await fetchData(
      `${domain}/seller/auction_associations/${auctionId}`,
      extraHeaders
    );

    handleErrors(result, "Error fetching stats", dispatch, error);

    return result.json();
  }
);

export const saveAssignmentComment = createAsyncThunk(
  "auctions/saveAssignmentComment",
  async ({ id, issueType, comments }, { getState, dispatch }) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const params = {
      issue_type: issueType,
      comments: comments,
    };
    const domain = getState().environment.REACT_APP_API_URL;
    const result = await putData(
      `${domain}/seller/assignments/${id}`,
      params,
      extraHeaders
    );

    if (result.ok) {
      logEvent(analytics, "comment", {
        assignment_id: id,
        issue_type: issueType,
        comments: comments,
        auction_id: getState().auctions.selectedAuctionId,
      });
      dispatch(success("Comment saved successfully"));
      return { id, issueType, comments };
    } else {
      handleErrors(result, "Error saving comment", dispatch, error);
    }
  }
);

export const saveDebrief = createAsyncThunk(
  "auctions/saveDebrief",
  async (
    { releaseFrom, releaseTo, releaseLocation, comments, redirectToAuction },
    { getState, dispatch }
  ) => {
    const extraHeaders = extraReqHeaders(
      getState().impersonate.impersonatedSeller
    );

    const auctionId = getState().auctions.selectedAuctionId;

    const params = {
      next_release_from: releaseFrom,
      next_release_to: releaseTo,
      next_release_location: releaseLocation,
      comments: comments,
    };
    const domain = getState().environment.REACT_APP_API_URL;
    const result = await postData(
      `${domain}/seller/auctions/${auctionId}/debriefs`,
      params,
      extraHeaders
    );

    if (result.ok) {
      dispatch(success("Debrief sent successfully"));
      redirectToAuction(auctionId);
      return result.json();
    } else {
      handleErrors(result, "Error sending debrief", dispatch, error);
    }
  }
);

export const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    auctionSelected: (state, action) => {
      state.selectedAuctionId = action.payload;
    },
    lotsDelivered: (state, action) => {
      const count = state.associations.delivered_lots_count || 0;
      state.associations.delivered_lots_count = count + action.payload;
    },
  },
  extraReducers: {
    [fetchAuctions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAuctions.fulfilled]: (state, action) => {
      auctionsAdapter.setAll(state, action);

      state.stats = [];

      if (action.payload.length && !state.selectedAuctionId) {
        state.selectedAuctionId = action.payload[0].id;
      }

      state.loading = false;
    },
    [fetchAuctions.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchAuction.fulfilled]: (state, action) => {
      auctionsAdapter.upsertOne(state, action.payload);
    },
    [fetchLots.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchLots.fulfilled]: (state, action) => {
      state.lots = action.payload;

      state.loading = false;
    },
    [fetchLots.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchAuctionStats.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAuctionStats.fulfilled]: (state, action) => {
      state.stats = action.payload;

      state.loading = false;
    },
    [fetchAuctionStats.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchAuctionAssociations.fulfilled]: (state, action) => {
      state.associations = action.payload;
    },
    [saveDebrief.fulfilled]: (state, action) => {
      state.associations.debrief_id = action.payload.id;
    },
    [saveAssignmentComment.fulfilled]: (state, action) => {
      const { id, issueType, comments } = action.payload;

      const lot = state.lots.find((lot) => lot.assignment?.id === id);
      if (lot) {
        lot.assignment.issue_type = issueType;
        lot.assignment.comments = comments;
      }
    },
  },
});

export const { selectAll: selectAllAuctions, selectById: selectAuctionById } =
  auctionsAdapter.getSelectors((state) => state.auctions);

export const selectLots = (state) => state.auctions.lots;
export const loadingAuction = (state) => state.auctions.loading;
export const selectStats = (state) => state.auctions.stats;
export const selectAssociations = (state) => state.auctions.associations;

export const selectSelectedAuction = createSelector(
  [
    (state) => state.auctions.entities,
    (state) => state.auctions.selectedAuctionId,
  ],
  (auctions, selectedAuctionId) => auctions[selectedAuctionId]
);

export const selectGroupedLotsForDebrief = createSelector(
  [(state) => state.auctions.lots],
  (lots) => {
    const soldLots = lots.filter((lot) => lot.assignment);

    return soldLots.reduce(
      (acc, lot) => {
        if (lot.assignment.delivered && lot.assignment.comments) {
          acc.withComments.push(lot);
        } else if (lot.assignment.delivered && !lot.assignment.comments) {
          acc.withoutComments.push(lot);
        } else if (!lot.assignment.delivered && lot.assignment.paid) {
          acc.undelivered.push(lot);
        } else if (!lot.assignment.paid && lot.assignment.comments) {
          acc.unpaidWithComments.push(lot);
        } else {
          acc.unpaidWithoutComments.push(lot);
        }

        return acc;
      },
      {
        withComments: [],
        withoutComments: [],
        unpaidWithComments: [],
        unpaidWithoutComments: [],
        undelivered: [],
      }
    );
  }
);

export const { auctionSelected, lotsDelivered } = auctionsSlice.actions;

export default auctionsSlice.reducer;
