import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ButtonWithIcon } from "vavato-ui";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import { offlineQueues } from "../utils/OfflineService";
import { syncOfflineDeliveries } from "../store/goodsHandoverSlice";
import GoodsHandoverTitle from "./GoodsHandoverTitle";
import ProgressBar from "./ProgressBar";

const OfflineSyncMessage = styled.div`
  color: ${(props) => props.theme.text};
  font-weight: 100;
`;

const GoodsHandoverSyncOffline = () => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const [offlineDeliveriesCount, setOfflineDeliveriesCount] = useState(0);
  const [totalToSync, setTotalToSync] = useState(0);

  const syncing = useSelector(
    (state) => state.goodsHandover.syncingOfflineDeliveries
  );

  const updateOfflineDeliveriesCount = useCallback(async () => {
    const count = await offlineQueues.deliveries.count();
    setOfflineDeliveriesCount(count);
  }, []);

  const subscribeDeliveryEvent = useCallback(
    (_primKey, _obj, transaction) => {
      transaction.on("complete", updateOfflineDeliveriesCount);
    },
    [updateOfflineDeliveriesCount]
  );

  useEffect(() => {
    updateOfflineDeliveriesCount();
  }, [updateOfflineDeliveriesCount]);

  useEffect(() => {
    offlineQueues.deliveries.hook("creating", subscribeDeliveryEvent);
    return () =>
      offlineQueues.deliveries
        .hook("creating")
        .unsubscribe(subscribeDeliveryEvent);
  }, [subscribeDeliveryEvent]);

  useEffect(() => {
    offlineQueues.deliveries.hook("deleting", subscribeDeliveryEvent);
    return () =>
      offlineQueues.deliveries
        .hook("deleting")
        .unsubscribe(subscribeDeliveryEvent);
  }, [subscribeDeliveryEvent]);

  const sync = () => {
    setTotalToSync(offlineDeliveriesCount);
    dispatch(syncOfflineDeliveries());
  };

  if (offlineDeliveriesCount <= 0) {
    return <GoodsHandoverTitle />;
  }

  return (
    <div>
      <GoodsHandoverTitle>
        <ButtonWithIcon
          icon={faSync}
          spin={syncing}
          style={{ padding: "8px", lineHeight: 0 }}
          onClick={sync}
        />
      </GoodsHandoverTitle>
      {syncing ? (
        <ProgressBar
          completed={totalToSync - offlineDeliveriesCount}
          total={totalToSync}
        />
      ) : (
        <OfflineSyncMessage>
          {t("offlineMessage", { count: offlineDeliveriesCount })}
        </OfflineSyncMessage>
      )}
    </div>
  );
};

export default GoodsHandoverSyncOffline;
