import { useTranslation } from "react-i18next";
import styled from "styled-components";

import imagePlaceholder from "../assets/image-placeholder.svg";

const LinkWithImage = styled.a`
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
`;

const LotLinkWithImage = ({ lotUrl, LotName, imageUrl }) => {
  const { t } = useTranslation("goodsHandover");

  const url = imageUrl || imagePlaceholder;

  return (
    <LinkWithImage
      href={lotUrl}
      target="_blank"
      rel="noopener noreferrer"
      title={LotName}
    >
      <img src={url} alt={t("lotAlternativeText")} />
      <div>{LotName}</div>
    </LinkWithImage>
  );
};

export default LotLinkWithImage;
