import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, ConfirmationModal } from "vavato-ui";

import CameraContainer from "./CameraContainer";
import SignatureInput from "./SignatureInput";
import PickupSummary from "./PickupSummary";
import ScrollableList from "./ScrollableList";
import CheckoutFooter from "./CheckoutFooter";
import EmptyState from "./EmptyState";
import DeliveredByInput from "./DeliveredByInput";
import TransporterForm from "./TransporterForm";

import {
  selectSelectedAssignments,
  selectSelectedGoodsHandovers,
  selectSelectedIdProof,
  selectValidTransporter,
  deliverAssignments,
  goodsHandoverForPickup,
} from "../store/goodsHandoverSlice";

import { selectAdvancedDeliverForm } from "../store/profileSlice";

const FixedFooter = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: flex-end;
  box-shadow: 0px -8px 6px -6px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledEmptyState = styled(EmptyState)`
  padding: 106px 0px;
`;

const PickupColumn = ({ showLotLightbox }) => {
  const { t } = useTranslation("goodsHandover");

  const dispatch = useDispatch();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const forPickup = useSelector(goodsHandoverForPickup);

  const selectedAssignments = useSelector(selectSelectedAssignments);
  const selectedGoodsHandovers = useSelector(selectSelectedGoodsHandovers);
  const selectedIdProof = useSelector(selectSelectedIdProof);
  const validTransporter = useSelector(selectValidTransporter);

  const delivering = useSelector((state) => state.goodsHandover.delivering);
  const advancedFormFields = useSelector(selectAdvancedDeliverForm);

  const selectedAssignmentsForGoodsHandover = (goodsHandover) => {
    return goodsHandover.assignments.filter((assignment) =>
      selectedAssignments.includes(assignment)
    );
  };

  const assignmentIdsByBuyerId = selectedGoodsHandovers.reduce(
    (result, goodsHandover) => {
      result[goodsHandover.buyer_entity.id] =
        selectedAssignmentsForGoodsHandover(goodsHandover).map(
          (assignment) => assignment.id
        );
      return result;
    },
    {}
  );

  const deliverPreparedAssignments = () => {
    dispatch(
      deliverAssignments({
        assignmentIdsByBuyerId,
        idProof: selectedIdProof,
        signaturePadRef,
        deliveredBy: currentDeliveredBy,
        advancedFormFields,
      })
    );
  };

  const signaturePadRef = useRef(null);
  const [deliveryDisabled, setDeliveryDisabled] = useState(true);
  const [hasSignature, setHasSignature] = useState(false);

  const onSignaturePadWrite = () => {
    setHasSignature(true);
  };
  const onSignaturePadErase = () => {
    setHasSignature(false);
  };

  const currentDeliveredBy = useSelector(
    (state) => state.goodsHandover.currentDeliveredBy
  );

  useEffect(() => {
    if (advancedFormFields) {
      const hasDeliveredBy = currentDeliveredBy !== "";
      const enabled =
        validTransporter && hasDeliveredBy && (selectedIdProof || hasSignature);

      setDeliveryDisabled(!enabled);
    } else {
      const enabled = selectedIdProof || hasSignature;

      setDeliveryDisabled(!enabled);
    }
  }, [
    selectedIdProof,
    hasSignature,
    currentDeliveredBy,
    validTransporter,
    advancedFormFields,
  ]);

  const handleDeliverClick = () => {
    if (signaturePadRef.current.isEmpty() || !selectedIdProof) {
      setConfirmationModalOpen(true);
    } else {
      deliverPreparedAssignments();
    }
  };

  if (!selectedGoodsHandovers.length) {
    return (
      <StyledEmptyState>
        <h3>{t("emptyLotsSelectedTitle")}</h3>
        {forPickup ? (
          <p>{t("emptyLotsSelectedDescription")}</p>
        ) : (
          <p>{t("emptyLotsSelectedDescriptionForDelivered")}</p>
        )}
      </StyledEmptyState>
    );
  }

  return (
    <>
      <ScrollableList className="details">
        {selectedGoodsHandovers.map((goodsHandover) => (
          <PickupSummary
            key={goodsHandover.buyer_entity.id}
            buyerEntity={goodsHandover.buyer_entity}
            assignments={selectedAssignmentsForGoodsHandover(goodsHandover)}
            showLotLightbox={showLotLightbox}
          />
        ))}

        {selectedAssignments.length && (
          <>
            {advancedFormFields && <TransporterForm />}
            <CameraContainer assignmentIdsByBuyerId={assignmentIdsByBuyerId} />
            <SignatureInput
              ref={signaturePadRef}
              onWrite={onSignaturePadWrite}
              onErase={onSignaturePadErase}
            />
            {advancedFormFields && <DeliveredByInput />}
          </>
        )}
      </ScrollableList>
      {selectedAssignments.length ? (
        <>
          <FixedFooter>
            <Button
              disabled={delivering || deliveryDisabled}
              onClick={handleDeliverClick}
            >
              {delivering ? t("delivering") : t("deliver")}
            </Button>
          </FixedFooter>
          <CheckoutFooter
            buttonText={delivering ? t("delivering") : t("deliver")}
            onClick={handleDeliverClick}
            disabled={delivering || deliveryDisabled}
          />
          <ConfirmationModal
            modalOpen={confirmationModalOpen}
            setModalOpen={setConfirmationModalOpen}
            title={t("confirmationModalTitle")}
            confirm={() => deliverPreparedAssignments()}
          ></ConfirmationModal>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default PickupColumn;
