import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table, formatCurrency } from "vavato-ui";

import { totalValue } from "../utils/ui";

import { loadingAuction, selectLots } from "../store/auctionsSlice";

const CloseAuctionBidsTable = () => {
  const { t } = useTranslation("auctions");
  const loading = useSelector(loadingAuction);
  const lots = useSelector(selectLots);

  if (loading) return null;

  const totalBidsCount = totalValue(lots, "bids_count");
  const totalBidsValue = totalValue(lots, "bids_value");
  const temporarilyAssignedLots = lots.filter((lot) => lot.temporarily_awarded);
  const wonLots = lots.filter(
    (lot) =>
      lot.bid_price_in_euro && lot.bid_price_in_euro >= lot.expected_sale_price
  );
  const assignedLots = lots.filter((lot) => lot.assignment);
  const unassignedLots = lots.filter((lot) => !lot.assignment);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("auctionTotal")}</th>
          <th className="number">{t("bidsTotal")}</th>
          <th className="number">{t("valueTotal")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("lots")}</td>
          <td className="number">{lots.length}</td>
          <td className="number">-</td>
        </tr>
        <tr>
          <td>{t("bids")}</td>
          <td className="number">{totalBidsCount}</td>
          <td className="number">{formatCurrency(totalBidsValue)}</td>
        </tr>
        <tr>
          <td>{t("bidders")}</td>
          <td className="number">{totalValue(lots, "unique_bidders_count")}</td>
          <td className="number">-</td>
        </tr>
        <tr>
          <td>{t("averageBidsLot")}</td>
          <td className="number">
            {(totalBidsCount / lots.length).toFixed(2)}
          </td>
          <td className="number">
            {formatCurrency(totalBidsValue / lots.length)}
          </td>
        </tr>
        <tr>
          <td>{t("expectedLotsValue")}</td>
          <td className="number">-</td>
          <td className="number">
            {formatCurrency(totalValue(lots, "expected_sale_price"))}
          </td>
        </tr>
        <tr>
          <td>{t("winningBids")}</td>
          <td className="number">{wonLots.length}</td>
          <td className="number">
            {formatCurrency(totalValue(wonLots, "bid_price_in_euro"))}
          </td>
        </tr>
        <tr>
          <td>{t("temporaryAwardedLots")}</td>
          <td className="number">{temporarilyAssignedLots.length}</td>
          <td className="number">
            {formatCurrency(
              totalValue(temporarilyAssignedLots, "bid_price_in_euro")
            )}
          </td>
        </tr>
        <tr>
          <td>{t("temporaryAwardedLotsPercentage")}</td>
          <td className="number">
            {((temporarilyAssignedLots.length / lots.length) * 100).toFixed(2)}%
          </td>
          <td className="number">-</td>
        </tr>
        <tr>
          <td>{t("winningBidsPercentage")}</td>
          <td className="number">
            {((wonLots.length / lots.length) * 100).toFixed(2)}%
          </td>
          <td className="number">-</td>
        </tr>
        <tr>
          <td>{t("assigned")}</td>
          <td className="number">{assignedLots.length}</td>
          <td className="number">
            {formatCurrency(totalValue(assignedLots, "bid_price_in_euro"))}
          </td>
        </tr>
        <tr>
          <td>{t("toBeAssigned")}</td>
          <td className="number">{unassignedLots.length}</td>
          <td className="number">
            {formatCurrency(totalValue(unassignedLots, "bid_price_in_euro"))}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default CloseAuctionBidsTable;
