import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SearchField } from "vavato-ui";

const QueryStringSearch = ({ selectedSearchQuery }) => {
  const { t } = useTranslation("goodsHandover");

  const history = useHistory();

  const [tempSearchQuery, setTempSearchQuery] = useState(
    `${selectedSearchQuery || ""}`
  );

  useEffect(() => {
    setTempSearchQuery(`${selectedSearchQuery || ""}`);
  }, [selectedSearchQuery]);

  const setSearchQueryOnEnter = (e) => {
    if (e.key !== "Enter") return;

    setSearchQuery(e.target.value);
  };

  const setSearchQuery = (value) => {
    const queryParams = new URLSearchParams(history.location.search);

    if (value) {
      queryParams.delete("buyerId");
      queryParams.delete("auctionId");
      queryParams.set("searchQuery", value);
    } else {
      queryParams.delete("searchQuery");
    }

    history.push(
      [history.location.pathname, queryParams.toString()]
        .filter((el) => el.length)
        .join("?")
    );
  };

  return (
    <SearchField
      shrink
      value={tempSearchQuery}
      placeholder={t("generalSearch")}
      onChange={(value) => setTempSearchQuery(value)}
      onKeyUp={setSearchQueryOnEnter}
    />
  );
};

export default QueryStringSearch;
