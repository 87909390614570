import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Autocomplete, FormField } from "vavato-ui";

import EntityOption from "../components/EntityOption";
import { handleJwt } from "../api-service";

import {
  impersonateSeller,
  currentImpersonatedSeller,
} from "../store/impersonateSlice";

import {
  impersonateAbility,
  selectLoadingProfile,
} from "../store/profileSlice";

const SellerAutocompleteContainer = styled.div`
  padding-right: 8px;
`;

const SignOutContainer = styled.div`
  padding: 0px 16px;
`;

const SignOut = styled.a`
  padding: 0 8px;
  font-size: 12px;
  cursor: pointer;

  &&:hover {
    text-decoration: none;
  }
`;

const ImpersonationContainer = () => {
  const { t } = useTranslation("impersonation");

  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector(selectLoadingProfile);
  const canImpersonate = useSelector(impersonateAbility);
  const impersonatedSeller = useSelector(currentImpersonatedSeller);

  const setImpersonatedSeller = (seller) => {
    history.push(history.location.pathname);
    dispatch(impersonateSeller(seller));
  };

  return (
    <>
      <SellerAutocompleteContainer>
        {!loading && canImpersonate ? (
          <FormField inline>
            <Autocomplete
              highlighted
              url="/seller/seller_entity_autocomplete"
              placeholder={t("placeholder")}
              value={impersonatedSeller}
              onSelect={(seller) => {
                if (seller) setImpersonatedSeller(seller);
              }}
              extraReqHeaders={handleJwt()}
              resultRenderer={(entity) => <EntityOption entity={entity} />}
            />
          </FormField>
        ) : (
          ""
        )}
      </SellerAutocompleteContainer>
      <SignOutContainer>
        {impersonatedSeller ? (
          <SignOut onClick={() => setImpersonatedSeller(null)}>
            {t("signOut")}
          </SignOut>
        ) : (
          ""
        )}
      </SignOutContainer>
    </>
  );
};

export default ImpersonationContainer;
