export const COMMENT_ISSUES = [
  "doesntWork",
  "notPresent",
  "incomplete",
  "incorrectDescribed",
  "wrongDescription",
  "resold",
  "alreadyPicked",
  "transportNotProperlyPrepared",
  "storageFee",
  "hiddenDefect",
];

export const AUCTION_STATES = [
  "not_published",
  "bidding_not_yet_open",
  "bidding_open",
  "bidding_closed",
  "retired",
];

export const GOODS_HANDOVER_INTERVALS = [
  "last_60_days",
  "today",
  "next_60_days",
];

export const PICKUP_ENTITIES = ["buyer", "transporter"];

export const DEFAULT_LANGUAGE = "en";

export const INDEXED_DB_VERSION = 3;

export const MOBILE_NAVIGATION_WIDTH = 768;

export const LOGISTICS_ROLE = "vavato_logistics";
