import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Dropdown } from "vavato-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  margin: 0 10px;
  font-size: 0.8em;
  line-height: 2em;
  display: flex;
  justify-content: flex-end;

  & :hover {
    cursor: pointer;
  }
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownTitle = styled.p`
  margin-right: 5px;
`;

const StyledDropdown = styled(Dropdown)`
  max-height: calc(100vh - 110px - 24px - 24px - 10px);
  top: 110px;
`;

const DropdownFilter = ({ children, selected }) => {
  const [open, setOpen] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      if (open && container.current && !container.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handler);
  });

  const openDropdown = () => {
    setOpen(!open);
  };

  return (
    <Container ref={container} onClick={openDropdown}>
      <DropdownHeader>
        <DropdownTitle>{selected}</DropdownTitle>
        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
      </DropdownHeader>
      <StyledDropdown open={open}>{children}</StyledDropdown>
    </Container>
  );
};

export default DropdownFilter;
