import { DirectUpload } from "@rails/activestorage";

import { getLocalHeaders } from "./api";

export const upload = async (file, extraHeaders) => {
  const url = new URL(
    `/direct_uploads`,
    process.env.REACT_APP_API_URL
  ).toString();

  const headers = { ...getLocalHeaders(), ...extraHeaders };

  const delegate = {
    directUploadWillCreateBlobWithXHR: (xhr) => {
      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });
    },
  };

  const uploader = new DirectUpload(file, url, delegate);

  const signedId = await new Promise((resolve, reject) => {
    uploader.create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob.signed_id);
      }
    });
  });

  return signedId;
};
